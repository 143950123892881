// import { extractUsernameFromEmail, generateRandomPassword } from './formutils'
export const handleSubmit = async (e, StrapiUrl) => {
    const email = e.target.email.value;

    let result = {
        icon: 'error',
        title: 'Error',
        text: 'Please try again later',
    };

    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {

        e.target.email.value = ''; // Clear input email

        result = {
            icon: 'info',
            title: 'Info',
            text: 'Please Provide Your Email Address',
        };
        return result;
    }

    try {

        const registerInfo = {
            data: {
                Email: email,
            }
        };

        const register = await fetch(`${StrapiUrl}/api/newsletters`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(registerInfo)
        });

        if (!register.ok) {
            result = {
                icon: 'error',
                title: 'Error',
                text: 'Email Already Subscribed',
            }
            return result;
        }

        const registerResponse = await register.json();

        if (registerResponse.hasOwnProperty("data")) {

            result = {
                icon: 'success',
                title: 'Success',
                text: 'Successfully registered for the newsletter',
            }
            return result;

        } else {
            e.target.email.value = ''; // Clear input email
            result = {
                icon: 'error',
                title: 'Error',
                text: 'Email Already Subscribed',
            }
            return result;
        }


    } catch (error) {

        e.target.email.value = ''; // Clear input email
        result = {
            icon: 'error',
            title: 'Error',
            text: 'Email Already Subscribed',
        }
        return result;
    }

};

// TODO: to add recaptcha
export const handleSubmitGetReport = async (e, StrapiUrl) => {

    let result = {
        icon: 'error',
        title: 'Error',
        text: 'Please try again later',
    };

    try {

        const ContactInfo = {
            data: e
        };

        const register = await fetch(`${StrapiUrl}/api/report-requesters`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ContactInfo)
        });
        if (!register.ok) {
            result = {
                icon: 'error',
                title: 'Error',
                text: 'Please try again later',
            }
            return result;
        }else {
            result = {
                icon: 'success',
                title: 'Success',
                html: "<p>Thank you for your interest!</p><p>The report is now downloading. Please wait for a moment. If the download doesn't start automatically, you can access it through this </p><a href='/media/reports/Uplo-MENA-Ecommerce-Report-2023.pdf' target='_blank'>link</a>",
                data : register
            }
            return result;
        } 


    } catch (error) {

        result = {
            icon: 'error',
            title: 'General Error',
            text: 'Please try again later',
        }
        return result;
    }

};

export const handleSubmitContact = async (e, StrapiUrl, token) => {

    let result = {
        icon: 'error',
        title: 'Error',
        text: 'Please try again later',
    };

    try {

        const ContactInfo = {
            data: e
        };

        const register = await fetch(`${StrapiUrl}/api/contacts`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-recaptcha-token': token

            },
            body: JSON.stringify(ContactInfo)
        });

        if (!register.ok) {



            result = {
                icon: 'error',
                title: 'Error',
                text: 'Please try again later',
            }
            return result;

        }
        const ContactResponse = await register.json();

        if (ContactResponse.hasOwnProperty("data") && ContactResponse.data !== null) {

            result = {
                icon: 'success',
                title: 'Success',
                text: 'Thank you for getting in touch! ',
            }
            return result;

        } else {
            result = {
                icon: 'error',
                title: 'Error',
                text: 'Please try again later',
            }
            return result;
        }


    } catch (error) {

        result = {
            icon: 'error',
            title: 'General Error',
            text: 'Please try again later',
        }
        return result;
    }

};

export const handleSubmitCV = async (formData, file, StrapiUrl, token) => {
    let result = {
        icon: 'error',
        title: 'Error',
        text: 'Please try again later',
    };


    try {

        const uploadfile = await fetch(`${StrapiUrl}/api/upload`, {
            method: "POST",
            headers: {
                'Accept': '*/*',
                'x-recaptcha-token': token
            },
            body: file
        });

        if (!uploadfile.ok) {
            return result;
        }

        const uploadfileResponse = await uploadfile.json();

        if (Array.isArray(uploadfileResponse) && uploadfileResponse.length > 0) {
            const fileId = uploadfileResponse[0].id;
            const updatedObject = {
                ...formData,
                File: fileId
            };
            const register = await fetch(`${StrapiUrl}/api/cvs`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-recaptcha-token': token
                },
                body: JSON.stringify({
                    data: updatedObject
                })
            });

            if (!register.ok) {
                return result;
            }

            const contactResponse = await register.json();

            if (contactResponse.hasOwnProperty("data") && contactResponse.data !== null) {
                result = {
                    icon: 'success',
                    title: 'Success',
                    text: 'Thank you for getting in touch! ',
                };
                return result;
            } else {
                return result;
            }
        } else {
            return result;
        }


    } catch (error) {
        return result;
    }

};
