import Router from 'components/Router';
import LiveChat from 'components/LiveChat';
import useReCaptcha from 'hooks/useReCaptcha';
import React, { useEffect, useState } from 'react';
import ReportPopUp from 'components/pages/homepage/ReportPopUp';
export const ReCaptchaContext = React.createContext();

function App() {
  const { showBadge, reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha();
  const [showReportPopUp, setShowReportPopUp] = useState(false);

  useEffect(() => {
      const isFirstVisit = localStorage.getItem('firstVisit') === null;
      if (isFirstVisit) {
          localStorage.setItem('firstVisit', 'true');
          setShowReportPopUp(true);
      }
  }, []);

  showBadge();

  return (
    <ReCaptchaContext.Provider value={{ reCaptchaLoaded, generateReCaptchaToken }}>
      {showReportPopUp && <ReportPopUp show={showReportPopUp} onClose={() => setShowReportPopUp(false)} />}
      <Router />
      <LiveChat />
    </ReCaptchaContext.Provider>
  );
}
export default App;
