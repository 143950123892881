import Header from 'components/sections/Header'
import Footer from 'components/sections/Footer'
import styles from '../styles/components/pages/Subscribe.module.scss';
import Swal from 'sweetalert2';
import { handleSubmit } from 'utils/strapiReq';
import style from '../styles/components/Swal.module.scss';
import content from '../content/pages/Subscribe.json'
import Container from 'components/sections/Container'
import Section from 'components/sections/Section'
import React, { useState } from 'react';

const Subscribe = () => {
    const [loading, setLoading] = useState(false);
    const StrapiUrl = process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "http://localhost:1337";
    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        handleSubmit(e, StrapiUrl)
            .then((res) => {
                Swal.fire({
                    title: res.title,
                    text: res.text,
                    icon: res.icon,
                    customClass: {
                        container: style.container,
                        title: style.title,
                        confirmButton: style.button,
                        closeButton: style.button,
                        cancelButton: style.button,
                        popup: style.container
                    }
                });
                e.target.email.value = '';
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setLoading(false);
            });
    }

    return (
        <>
            <Header />
            <Section className={styles.section}>
                <Container className={styles.container}>
                    <div className={styles.content}>

                        <h3 className={styles.title}>{content.title}</h3>
                        <hr />
                        <form className={styles.form} onSubmit={submit}>
                            <label className={styles.label} htmlFor="email">
                                {content.label}
                            </label>

                            <div className={styles.actions}>
                                <input type="text" id="email" className={styles.input}
                                    placeholder={content.actions.placeholder} />
                                <button type="submit" className={styles.submit} disabled={loading}>

                                    {loading ? (
                                        "loading..."
                                    ) :
                                        (content.actions.submit)
                                    }

                                </button>
                            </div>
                        </form>
                    </div>
                </Container>
            </Section>
            <Footer />
        </>
    )
}

export default Subscribe