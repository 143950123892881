import React, { useState, useRef, useEffect } from 'react';
import styles from 'styles/components/sections/BookMeeting.module.scss';

const BookMeeting = ({content}) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup();
    }
  };

  useEffect(() => {
    if (isOpen) {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]);

  return (
    <div>
      <button className={styles.link} onClick={openPopup}>{content.button}</button>
      {isOpen && (
        <div className={styles.popupOverlay} onClick={handleClickOutside}>
          <div ref={popupRef}>
            <div className={`${styles.calContainer} calendly-inline-widget`} data-url="https://calendly.com/uplo-introductory/introductory-call"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookMeeting;
