import Header from 'components/sections/Header'
import BlogSide from 'components/pages/blog/BlogSide'
import BlogContent from 'components/pages/blog/BlogContent'
import ContactUs from 'components/sections/ContactUs'
import Footer from 'components/sections/Footer'
import Blogs from '../content/pages/Blog'
import { useLocation } from 'react-router-dom';
import React from 'react';
import ReportPopUp from 'components/pages/homepage/ReportPopUp';

const Blog = () => {
    const location = useLocation();
    const path = location.pathname;
    let content;
    let allContent = Blogs;
    if (path === '/blog/from-bicks-toClicks') {
        content = Blogs.fromBricksToClicksBlog;
    } else if (path === '/blog/conversation-and-commerce') {
        content = Blogs.conversationAndCommerce;
    } else if (path === '/blog/loreal-success-story') {
        content = Blogs.lorealSuccessStory;
    } else if (path === '/blog/black-friday-record') {
        content = Blogs.blackFridayRecord;
    } else if (path === '/blog/fashion-d2c') {
        content = Blogs.fashionD2C;
    } else if (path === '/blog/omni-channel-survey') {
        content = Blogs.omniChannelSurvey;
    } else if (path === '/blog/online-shopping-eid') {
        content = Blogs.onlineShoppingEid;
    } else if (path === '/blog/beauty-and-personal-care') {
        content = Blogs.beautyAndPersonalCare;
    } else if (path === '/blog/refunds-and-returns') {
        content = Blogs.refundsAndReturns;
    } else if (path === '/blog/mena-region-brands') {
        content = Blogs.menaRegionBrands;
    } else if (path === '/blog/generate-sales') {
        content = Blogs.generateSales;
    } else if (path === '/blog/online-retail-trends') {
        content = Blogs.onlineRetailTrends;
    } else if (path === '/blog/mena-ecommerce-report-2020') {
        content = Blogs.menaEcommerceReport2022;
    } else if (path === '/blog/uae-state-of-retail-report-2022') {
        content = Blogs.uaeStateOfRetailReport2022;
    } else if (path === '/blog/power-of-ecommerce-marketplaces') {
        content = Blogs.powerOfEcommerceMarketplaces;
    } else if (path === '/blog/mena-online-retail-report-2023') {
        content = Blogs.menaOnlineRetailReport2023;
    } else if (path === '/blog/supply-chain-from-factory-till-last-mile') {
        content = Blogs.SupplyChainFromFactoryTillLastMile;
    } else  {
        content = 'default content';
    }

    return (
        <>
            <Header position={'absolute'} iconColor="white" mobileIconColor="black" />
            <BlogContent content={content} />
            <BlogSide content={content} allContent={allContent}/>
            <ContactUs />
            <Footer />
        </>
    )
}

export default Blog
