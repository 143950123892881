import styles from 'styles/components/sections/VisionCard.module.scss'

const VisionCard = ({Icon, title, text, isShadow = false}) => {
    return (
        <div className={`${styles.card} ${isShadow ? styles.shadow : ''} `}>
            {Icon}
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.description}>{text}</p>
        </div>
    )
}

export default VisionCard
