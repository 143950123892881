import styles from 'styles/components/pages/contactus/Map.module.scss'
import 'styles/components/pages/contactus/Map.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, {useEffect, useRef} from 'react'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_TOKEN;

const Map = () => {
    const mapContainer = useRef(null)

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/aremaity/clhuanymr024h01qyhv8m19l3',
            center: [55.1168601, 24.9098299], // Dubai
            zoom: 12,
            scrollZoom      : false,
            boxZoom         : false,
            doubleClickZoom : false
        })

        // Add multiple markers for fixed locations
        const locations = [
            {name: 'Dubai', coordinates: [55.1168601, 24.9098299]},
        ]

        locations.forEach(location => {
            new mapboxgl.Marker()
                .setLngLat(location.coordinates)
                .setPopup(new mapboxgl.Popup().setHTML(location.name))
                .addTo(map)
        })

        return () => map.remove()
    }, [])

    return <div ref={mapContainer} className={styles.mapContainer}/>
}

export default Map
