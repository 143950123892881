import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

const ScrollToTop = () => {
    const location = useLocation()

    useEffect(() => {
        if (
            location.state &&
            typeof location.state === 'object' &&
            location.state.scroll === false
        ) {
            return
        }

        window.scrollTo(0, 0)
    }, [location.pathname, location.state])

    return null
}

export default ScrollToTop
