import React, { Children, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useSprings, animated, to } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

import styles from 'styles/components/sections/StackCards.module.scss'

const StackCards = ({ children, xShift, scaleShift, IsOurMarket }) => {
  const cards = Children.toArray(children)
  const cardsCount = cards.length

  const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out

  const initialState = (i, length = cardsCount) => ({
    x: (length - (i + 1)) * xShift,
    y: 0,
    rotate: 0,
    scale: 1 - ((length - (i + 1)) * scaleShift),
    delay: i * 100,
  })

  const [springs, api] = useSprings(cardsCount, i => ({
    ...initialState(i),
    from: { x: 0, y: 0, rot: 0, scale: 1 },
  })) // Create a bunch of springs using the helpers above

  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(({ args: [index], active, movement: [mx], direction: [xDir], velocity: [vx] }) => {
    const trigger = vx > 0.2 // If you flick hard enough it should trigger the card to fly out
    if (!active && trigger) {
      // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      gone.add(index)
      api.start(i => {
        if (!gone.has(i)) {
          return initialState(i, cardsCount - gone.size)
        }
      })
    }
    api.start(i => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(index)
      const x = isGone ? window.innerWidth * xDir : active ? mx : 0 // When a card is gone it flies out left or right, otherwise goes back to 0
      const scale = active ? 1.05 : 1 // Active cards scale-up a bit
      return {
        x,
        scale,
        delay: undefined,
        config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 },
      }
    })
    if (!active && gone.size === cardsCount) {
      setTimeout(() => {
        gone.clear()
        api.start(i => initialState(i))
      }, 500)
    }
  })

  const activateSlide = index => {
    gone.clear()
    for (let i = index + 1; i < cardsCount; i++) {
      gone.add(i)
    }

    api.start(i => {
      const isGone = gone.has(i)
      if (!isGone) {
        return initialState(i, cardsCount - gone.size)
      }

      if (index + 1 <= i) {
        const x = window.innerWidth * (springs[i].x.get() < -window.innerWidth ? -1 : 1)
        return {
          x,
          delay: undefined,
          config: { friction: 50, tension: 500 },
        }
      }
    })
  }

  return (
    <>
      {springs.map(({ x, y, rot, scale }, i) => (
        <animated.div
          className={IsOurMarket ? styles.cardWrapperMarket : styles.cardWrapper}
          key={i}
          {...bind(i)}
          style={{ x, y, transform: to([rot, scale], (r, s) => `scale(${s})`) }}
        >
          {cloneElement(cards[i], {
            ...cards[i].props,
            index: i,
            cardsCount,
            activateSlide,
          })}
        </animated.div>
      ))}
    </>
  )
}

StackCards.defaultProps = {
  xShift: -20,
  scaleShift: 0.025,
  IsOurMarket: false, // Default value for IsOurMarket
}

StackCards.propTypes = {
  xShift: PropTypes.number,
  scaleShift: PropTypes.number,
  IsOurMarket: PropTypes.bool, // Prop type for IsOurMarket
  children: PropTypes.node,
}

export default StackCards
