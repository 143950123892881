import styles from 'styles/components/pages/partners/KPI.module.scss'

import {default as SectionKPI} from 'components/sections/KPI'

const KPI = (content) => {
    return (
        <div className={styles.kpi}>
            <div className={styles.greenTriangle}></div>
            <div className={styles.blackTriangle}></div>
            <SectionKPI content={content} className={styles.sectionKPI} isDark={true}/>
        </div>
    )
}

export default KPI
