import styles from 'styles/components/pages/aboutus/Testimonials.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import BrandsTile from 'components/pages/homepage/BrandsTile'
import Image from 'components/Image'

import {ReactComponent as Shapes} from 'assets/homepage/shapes.svg'

const Testimonials = ({content}) => {
    return (
        <Section className={styles.testimonials}>
            <Container className={styles.container}>
                {
                    content.titles.map((title, index) => (
                        <h4 key={index} className={styles.title}>
                            {title}
                        </h4>
                    ))
                }

                <div className={styles.tiles}>
                    {
                        content.tiles.map((tile, index) => (
                            <BrandsTile key={index} defaultHovered={tile.defaultHovered} activateHover={true}
                                        className={`${styles.tile} ${styles[`tile--${tile.tileBg}`]}`}
                                        tileColor={+tile.tileColor}
                                        frontSideHtml={(
                                            <div className={styles.frontContent}>
                                                <Image src={tile.frontSideHtml.image} className={styles.frontSideImage}/>
                                                <div className={styles.overlay}></div>
                                                <div className={styles.text}>
                                                    {
                                                        tile.frontSideHtml.texts.map((text, index) => (
                                                            <h3 key={index}>{text}</h3>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        backSideHtml={(
                                            <div className={styles.backSide}>
                                                {
                                                    tile.backSideHtml.authorLines.map((line, index) => (
                                                        <p className={styles.author} key={index}>{line}</p>
                                                    ))
                                                }
                                                <span className={styles.quote}>“</span>
                                                <p className={styles.subtext}>
                                                    {tile.backSideHtml.subtext}
                                                </p>
                                            </div>
                                        )}/>
                        ))
                    }
                </div>
                <div className={styles.shapesWrapper}>
                    <Shapes className={styles.shapes}/>
                </div>
            </Container>
        </Section>
    )
}

export default Testimonials
