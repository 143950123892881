import React, { useState } from 'react';

import Header from 'components/sections/Header'
import Categories from 'components/pages/lab/Categories'
import Blogs from 'components/pages/lab/Blogs'
// import Banner from 'components/pages/lab/Banner'
import Footer from 'components/sections/Footer'
import content from 'content/pages/Lab.json'

const Lab = () => {
  const [activeCategories, setActiveCategories] = useState(() => {
    const initialActiveCategories = content.categories.list.reduce(
      (acc, category) => ({
        ...acc,
        [category.title]: true,
      }),
      {}
    );

    // Set a specific category as active
    initialActiveCategories['News'] = true;

    return initialActiveCategories;
  });

  const toggleCategory = (category) => {
    setActiveCategories((prevActiveCategories) => ({
      ...prevActiveCategories,
      [category]: !prevActiveCategories[category],
    }));
  };



  const getCountActiveCategories = () =>
    Object.values(activeCategories).filter(value => value).length

  return (
    <>
      <Header iconColor="white" logoColor="white" bgBlack />
      <Categories activeCategories={activeCategories} content={content.categories}
        getCountActiveCategories={getCountActiveCategories}
        toggleCategory={toggleCategory} />
      <Blogs content={content.blogs}
        activeCategories={activeCategories} />
      {/* <Banner content={content.banner}/> */}
      <Footer />
    </>
  )
}

export default Lab
