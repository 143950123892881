import Header from 'components/sections/Header'
import Service from 'components/pages/solutions/Service'
import PartnersClients from 'components/sections/PartnersClients'
// import Stories from 'components/sections/Stories'
import Brands from 'components/sections/Brands'
import ContactUs from 'components/sections/ContactUs'
import Footer from 'components/sections/Footer'
import React from 'react';
import content from 'content/pages/Solutions.json'

const Solutions = () => {
    return (
        <>
            <Header bgBlack iconColor="white" logoColor="white" />
            <Service content={content.service} />
            <PartnersClients content={content.partnersClients} />
            {/* <Stories content={content.stories}/> */}
            <Brands activateHover={false} content={content.brands} />
            <ContactUs />
            <Footer />
        </>
    )
}

export default Solutions
