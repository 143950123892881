import Header from 'components/sections/Header'
import React, { useEffect, useState } from 'react';
import { default as HomeBanner } from 'components/pages/homepage/Banner'
import Ribbon from 'components/pages/homepage/Ribbon'
import WideBanner from 'components/pages/homepage/WideBanner'
import Service from 'components/pages/homepage/Service'
import KPI from 'components/sections/KPI'
import Banner from 'components/sections/Banner'
import Brands from 'components/sections/Brands'
// import Stories from 'components/sections/Stories'
import FAQ from 'components/pages/homepage/FAQ'
import PartnersClients from 'components/sections/PartnersClients'
import Lab from 'components/pages/homepage/Lab'
import Email from 'components/pages/homepage/Email'
import Footer from 'components/sections/Footer'
import MapImage from 'assets/homepage/map.webp'
import ScrollToTop from 'components/ScrollToTop'

import content from 'content/pages/Homepage-edit.json'

const Homepage = () => {
    return (
        <>

            <ScrollToTop />
            <Header />
            <HomeBanner content={content.homeBanner} />
            <Ribbon content={content.ribbon} isDarkMode />
            <WideBanner isGreenTriangle isBlackTriangle
                content={content.banner} imageSrc={content.WideBannerImg} />
            <Service content={content.service} />
            <KPI />
            <Banner content={content.mapBanner}
                imageSrc={MapImage} />
            <Brands activateHover={false} content={content.brands} />
            {/* <Stories content={content.stories} /> */}
            <FAQ content={content.faq} isHomePage={true} />
            <PartnersClients content={content.partnersClients} isDark />
            <Lab content={content.lab} />
            <Email content={content.email} />
            <Footer />

        </>
    )
}

export default Homepage
