import styles from 'styles/components/pages/blog/BlogContent.module.scss'
import Swal from 'sweetalert2';
import style from '../../../styles/components/Swal.module.scss';
import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import TwitterShareButton from './TwitterShareButton'
import ShareFaceBookButton from './FacebookShareButton'
import WhatsAppShareButton from './WhatsAppShareButton'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { BsShare } from 'react-icons/bs'
import React, { useState, useRef } from 'react';
import { handleSubmitGetReport } from 'utils/strapiReq';

const BlogContent = ({ content }) => {
    const [formValues, setFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const StrapiUrl = process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "http://localhost:1338";
    const hiddenInputRef = useRef(); // Create a ref for the hidden input

    function formatDate(dateString) {
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long'
        }).format(new Date(dateString));

        return formattedDate;
    }

    const handleOpenPdf = (event, pdfUrl) => {
        event.preventDefault();
        window.open(pdfUrl, '_blank');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const errors = {};
        // Validate email field
        if (!formValues.Email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.Email)) {
            errors.Email = 'Invalid email format';
        }
        // Validate required fields
        content.form.fields.forEach((field) => {
            if (field.required && !formValues[field.name]) {
                errors[field.name] = 'This field is required';
            }
        });
        const requestKeyId = hiddenInputRef.current.value; // Get the hidden input's value

        // In case the error object is empty
        if (Object.keys(errors).length === 0) {
            e.preventDefault();
            console.log(formValues);
            const data = {
                "Name": formValues.FullName,
                "Email": formValues.Email,
                "file": {
                    "set": [{ "id": requestKeyId }]
                }
            };
            setLoading(true);
            handleSubmitGetReport(data, StrapiUrl).then(response => {

                Swal.fire({
                    title: response.title,
                    text: response.text,
                    icon: response.icon,
                    html: response.html,
                    customClass: {
                        container: style.container,
                        title: style.title,
                        confirmButton: style.button,
                        closeButton: style.button,
                        cancelButton: style.button,
                        popup: style.container
                    }
                });
                if (response.title === "Success") {
                    const headers = {};
                    response.data?.headers.forEach((value, name) => {
                        headers[name] = value;
                    });

                    if (headers['content-type'] === "application/pdf") {
                        return response.data.blob();

                    } return false;
                }
                return false;

            })
                .then(pdfBlob => {
                    if (pdfBlob) {
                        // Create a URL for the Blob
                        const url = URL.createObjectURL(pdfBlob);

                        // Create a link element to trigger the download
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'Uplo-MENA-Ecommerce-Report-2023.pdf'; // Set the desired file name

                        // Trigger a click event to start the download
                        a.click();

                        // Release the Blob URL after the download
                        URL.revokeObjectURL(url);
                    }

                    setLoading(false);
                    setFormValues({});
                })
                .catch(error => {
                    // Handle errors here, including network errors or errors from the server
                    console.error('Error:', error);
                })
        } else {
            setFormErrors(errors);
            setLoading(false);
        }
    };

    return (
        <Section className={styles.BlogContent}>
            <Container>
                <div className={styles.MainText}>
                    <h1>{content.title}</h1>
                    <p>{content.subTitle}</p>
                    <div className={styles.bottomInfo}>
                        <div className={styles.date}>
                            <FaRegCalendarAlt size={20} color={'#121A1F'} />
                            <h3>{formatDate(content.date)}</h3>
                        </div>
                        <div className={styles.socials}>
                            <BsShare size={30} className={styles.cursorDefault} />
                            <ShareFaceBookButton url={window.location.href} hashTag={'UPLO'} />
                            <TwitterShareButton url={window.location.href} title={content.title} via={'UPLO'} imageUrl={content.headerImage} hashTag={'UPLO'} />
                            <WhatsAppShareButton url={window.location.href} title={content.title} hashTag={'UPLO'} />
                        </div>
                    </div>
                </div>
                <div className={styles.Paragraphs}>
                    {content.firstImage ? (
                        <img src={content.firstImage} alt='Blog' />
                    ) : null}
                    <p>{content.introduction}</p>
                    {
                        content.text1 &&
                        content.text1.map((text, index) => (
                            <p key={index}>
                                {text}
                            </p>
                        ))
                    }
                    {
                        content.textWithTitle &&
                        content.textWithTitle.map((text, index) => (
                            <div key={index}>
                                <h3>{text.title}</h3>
                                {text.text &&
                                    text.text.map((text2, index) => (
                                        <p key={index}>
                                            {text2}
                                        </p>
                                    ))}
                                    <>
                                        {text.textAndLink &&
                                            text.textAndLink.map((tl, index) => (
                                                <div key={index}>
                                                    <p>
                                                        {tl.textBefore} <a href={tl.link}>{tl.linkText}</a> {tl.textAfter}
                                                    </p>
                                                    {tl.text &&
                                                        tl.text.map((text, index) => (
                                                            <p key={index}>{text}</p>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </>

                                    <h3>{text.imgTitle}</h3>
                                    {text.image ? (
                                        <img src={text.image} alt="Blog" />
                                    ) : null}
                            </div>
                        ))
                    }
                    {content.image ? (
                        <img src={content.image} alt='Blog' />
                    ) : null}
                    <ul>
                        {
                            content.numberBullets &&
                            content.numberBullets.map((text, index) => (
                                <li key={index}>
                                    {text}
                                </li>
                            ))
                        }
                    </ul>
                    {
                        content.textWithTitleAndBullet &&
                        content.textWithTitleAndBullet.map((p, index) => (
                            <div key={index}>
                                <div>
                                    <h3>{p.paragraph.title}</h3>
                                    {p.paragraph.titleImage ? (
                                        <img src={p.paragraph.titleImage} alt="Blog" />
                                    ) : null}
                                    <>
                                        {p.paragraph.text &&
                                            p.paragraph.text.map((text, index) => (
                                                <p key={index}>{text}</p>
                                            ))
                                        }
                                    </>
                                    <>
                                        {p.paragraph.textAndLink &&
                                            p.paragraph.textAndLink.map((tl, index) => (
                                                <div key={index}>
                                                    <p>
                                                        {tl.textBefore} <a href={tl.link}>{tl.linkText}</a> {tl.textAfter}
                                                    </p>
                                                    {tl.text &&
                                                        tl.text.map((text, index) => (
                                                            <p key={index}>{text}</p>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </>
                                    <ol>
                                        {p.paragraph.bullets &&
                                            p.paragraph.bullets.map((bullet, index) => (
                                                <li key={index}>{bullet}</li>
                                            ))}
                                    </ol>
                                    <ul>
                                        {
                                            p.paragraph.numberBullets &&
                                            p.paragraph.numberBullets.map((text, index) => (
                                                <li key={index}>
                                                    {text}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <h3>{p.paragraph.imgTitle}</h3>
                                    {p.paragraph.image ? (
                                        <img src={p.paragraph.image} alt="Blog" />
                                    ) : null}
                                </div>
                            </div>
                        ))
                    }
                    {
                        content.text2 &&
                        content.text2.map((text) => (
                            <p>
                                {text}
                            </p>
                        ))
                    }
                    {
                        content.text3 &&
                        content.text3.map((text) => (
                            <p className={styles.text3}>
                                {text}
                            </p>
                        ))
                    }
                    {
                        content.textWithTitle2 &&
                        content.textWithTitle2.map((text, index) => (
                            <div key={index}>
                                <h3>{text.title}</h3>
                                {text.text &&
                                    text.text.map((text2, index) => (
                                        <p key={index}>
                                            {text2}
                                        </p>
                                    ))}
                                    <>
                                        {text.textAndLink &&
                                            text.textAndLink.map((tl, index) => (
                                                <div key={index}>
                                                    <p>
                                                        {tl.textBefore} <a href={tl.link}>{tl.linkText}</a> {tl.textAfter}
                                                    </p>
                                                    {tl.text &&
                                                        tl.text.map((text, index) => (
                                                            <p key={index}>{text}</p>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </>

                                    <h3>{text.imgTitle}</h3>
                                    {text.image ? (
                                        <img src={text.image} alt="Blog" />
                                    ) : null}
                            </div>
                        ))
                    }
                    {content.image2 ? (
                        <img src={content.image2} alt='Blog' />
                    ) : null}

                    {
                        content.text4 &&
                        content.text4.map((text) => (
                            <p className={styles.text4}>
                                {text}
                            </p>
                        ))
                    }

                    {
                        <p>
                            {content.footer}<a href={content.footerLink}>{content.footerLinkText}</a>
                        </p>
                    }
                    {
                        content.pdf &&
                        <p>
                            To read the full report,
                            <a
                                href={content.pdf}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(event) => handleOpenPdf(event, content.pdf)}>
                                &nbsp;click here
                            </a>
                        </p>
                    }
                </div>
                {
                    content.form &&
                    <>
                        <p className={styles.accessText}>
                            {content.form.access}
                        </p>
                        <form className={styles.formContent}>

                            {content.form.fields &&
                                content.form.fields.map((field, i) => (
                                    <div key={i} className={`${styles.field} ${styles.field__half}`}>
                                        <label>
                                            {field.label}
                                            {field.required && <span className={styles.required}> *</span>}
                                        </label>
                                        <input
                                            type={field.type}
                                            placeholder={field.placeHolder}
                                            name={field.name}
                                            value={formValues[field.name] || ''}
                                            onChange={(e) =>
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    [field.name]: e.target.value,
                                                }))
                                            }
                                            required={field.required}
                                        />
                                        {formErrors[field.name] &&
                                            <p className={styles.error}>{formErrors[field.name]}</p>
                                        }
                                    </div>
                                ))}

                            <input
                                type="hidden"
                                name="requestkey"
                                ref={hiddenInputRef}
                                value="1"
                            />
                            <button className={styles.button} onClick={handleSubmit} disabled={loading}>
                                {loading ? (
                                    "loading..."
                                ) :
                                    "Get It Now"
                                }
                            </button>
                        </form>
                    </>
                }
            </Container>
        </Section>
    )
}

export default BlogContent
