import React from 'react';
import {IoLogoWhatsapp} from 'react-icons/io'

const WhatsAppShareButton = ({ url, hashTag, title }) => {
  const encodedUrl = encodeURIComponent(url || '');
  const encodedHashTag = encodeURIComponent(hashTag || '');
  const encodedTitle = encodeURIComponent(title || '');
  const shareUrl = `https://api.whatsapp.com/send?text=${encodedUrl}%0A${encodedTitle}%0A%23${encodedHashTag}`;

  return (
    <div>
      <a href={shareUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
      <IoLogoWhatsapp size={35} />
      </a>
    </div>
  );
};

export default WhatsAppShareButton;