import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import styles from 'styles/components/Icon.module.scss'

const Icon = ({className, name, size = 1000, smallSize = size, ...props}) => {
    const [style, setStyle] = useState({})
    const [Size, setSize] = useState(size)
    const [Scale, setScale] = useState(1)

    useEffect(() => {
        const onResize = () => {
            setSize(window.innerWidth < 768 ? smallSize : size)
        }
        onResize()
        window.addEventListener('resize', onResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (Size !== 1000) {
            setScale(Size / 1000)
        }
    }, [Size])

    useEffect(() => {
        switch (name) {
            case 'horn':
                setStyle({
                    height: 9100 * Scale,
                    backgroundPositionX: -390 * Scale,
                    backgroundPositionY: -415 * Scale,
                })
                break
            case 'purpose':
                setStyle({
                    height: 12000 * Scale,
                    backgroundPositionX: -2096 * Scale,
                    backgroundPositionY: -813 * Scale,
                })
                break
            case 'vision':
                setStyle({
                    height: 13400 * Scale,
                    backgroundPositionX: -3988 * Scale,
                    backgroundPositionY: -998 * Scale,
                })
                break
            case 'mission':
                setStyle({
                    height: 13600 * Scale,
                    backgroundPositionX: -5680 * Scale,
                    backgroundPositionY: -991 * Scale,
                })
                break
            case 'horn-mail':
                setStyle({
                    height: 13000 * Scale,
                    backgroundPositionX: -6936 * Scale,
                    backgroundPositionY: -827 * Scale,
                })
                break
            case 'clients':
                setStyle({
                    height: 11800 * Scale,
                    backgroundPositionX: -550 * Scale,
                    backgroundPositionY: -2229 * Scale,
                })
                break
            case 'orders':
                setStyle({
                    height: 17200 * Scale,
                    backgroundPositionX: -3110 * Scale,
                    backgroundPositionY: -3498 * Scale,
                })
                break
            case 'rate':
                setStyle({
                    height: 14700 * Scale,
                    backgroundPositionX: -4355 * Scale,
                    backgroundPositionY: -2872 * Scale,
                })
                break
            case 'score':
                setStyle({
                    height: 13100 * Scale,
                    backgroundPositionX: -5405 * Scale,
                    backgroundPositionY: -2540 * Scale,
                })
                break
            case 'analytics':
                setStyle({
                    height: 12900 * Scale,
                    backgroundPositionX: -6874 * Scale,
                    backgroundPositionY: -2577 * Scale,
                })
                break
            case 'branding':
                setStyle({
                    height: 13500 * Scale,
                    backgroundPositionX: -703 * Scale,
                    backgroundPositionY: -4229 * Scale,
                })
                break
            case 'strategy':
                setStyle({
                    height: 15500 * Scale,
                    backgroundPositionX: -2709 * Scale,
                    backgroundPositionY: -4944 * Scale,
                })
                break
            case 'cream':
                setStyle({
                    height: 15500 * Scale,
                    backgroundPositionX: -4578 * Scale,
                    backgroundPositionY: -4852 * Scale,
                })
                break
            case 'black-friday':
                setStyle({
                    height: 14000 * Scale,
                    backgroundPositionX: -5811 * Scale,
                    backgroundPositionY: -4343 * Scale,
                })
                break
            case 'linkedin':
                setStyle({
                    height: 34800 * Scale,
                    backgroundPositionX: -18404 * Scale,
                    backgroundPositionY: -11586 * Scale,
                })
                break
            case 'facebook':
                setStyle({
                    height: 34800 * Scale,
                    backgroundPositionX: -19599 * Scale,
                    backgroundPositionY: -11586 * Scale,
                })
                break
            case 'whatsapp':
                setStyle({
                    height: 32700 * Scale,
                    backgroundPositionX: -19535 * Scale,
                    backgroundPositionY: -10889 * Scale,
                })
                break
            case 'instagram':
                setStyle({
                    height: 34000 * Scale,
                    backgroundPositionX: -21551 * Scale,
                    backgroundPositionY: -11316 * Scale,
                })
                break
            case 'tiktok':
                setStyle({
                    height: 29500 * Scale,
                    backgroundPositionX: -15611 * Scale,
                    backgroundPositionY: -10880 * Scale,
                })
                break
                case 'youtube':
                    setStyle({
                        height: 29500 * Scale,
                        backgroundPositionX: -16685 * Scale,
                        backgroundPositionY: -10880 * Scale,
                    })
                    break
            case 'phone':
                setStyle({
                    height: 18600 * Scale,
                    backgroundPositionX: -1090 * Scale,
                    backgroundPositionY: -7843 * Scale,
                })
                break
            case 'map':
                setStyle({
                    height: 16500 * Scale,
                    backgroundPositionX: -2585 * Scale,
                    backgroundPositionY: -6901 * Scale,
                })
                break
            case 'email':
                setStyle({
                    height: 17200 * Scale,
                    backgroundPositionX: -4344 * Scale,
                    backgroundPositionY: -7233 * Scale,
                })
                break
            case 'message':
                setStyle({
                    height: 18900 * Scale,
                    backgroundPositionX: -6482 * Scale,
                    backgroundPositionY: -8043 * Scale,
                })
                break
            case 'welcome':
                setStyle({
                    height: 21000 * Scale,
                    backgroundPositionX: -8960 * Scale,
                    backgroundPositionY: -8976 * Scale,
                })
                break
            case 'person-1':
                setStyle({
                    height: 13100 * Scale,
                    backgroundPositionX: -695 * Scale,
                    backgroundPositionY: -6994 * Scale,
                })
                break
            case 'person-2':
                setStyle({
                    height: 13100 * Scale,
                    backgroundPositionX: -1779 * Scale,
                    backgroundPositionY: -6994 * Scale,
                })
                break
            case 'person-3':
                setStyle({
                    height: 13100 * Scale,
                    backgroundPositionX: -2863 * Scale,
                    backgroundPositionY: -6994 * Scale,
                })
                break
            case 'person-4':
                setStyle({
                    height: 13100 * Scale,
                    backgroundPositionX: -3900 * Scale,
                    backgroundPositionY: -6994 * Scale,
                })
                break
            case 'person-5':
                setStyle({
                    height: 13100 * Scale,
                    backgroundPositionX: -4850 * Scale,
                    backgroundPositionY: -6994 * Scale,
                })
                break
            case 'news':
                setStyle({
                    height: 19300 * Scale,
                    backgroundPositionX: -1155 * Scale,
                    backgroundPositionY: -13189 * Scale,
                })
                break
            case 'success stories':
                setStyle({
                    height: 18100 * Scale,
                    backgroundPositionX: -2359 * Scale,
                    backgroundPositionY: -12338 * Scale,
                })
                break
            case 'educational videos':
                setStyle({
                    height: 18000 * Scale,
                    backgroundPositionX: -3653 * Scale,
                    backgroundPositionY: -12267 * Scale,
                })
                break
            case 'insights':
                setStyle({
                    height: 16500 * Scale,
                    backgroundPositionX: -4492 * Scale,
                    backgroundPositionY: -11202 * Scale,
                })
                break
            case 'e-commerce dictionary':
                setStyle({
                    height: 17800 * Scale,
                    backgroundPositionX: -6176 * Scale,
                    backgroundPositionY: -12125 * Scale,
                })
                break
            case 'press':
                setStyle({
                    height: 18000 * Scale,
                    backgroundPositionX: -7548 * Scale,
                    backgroundPositionY: -12268 * Scale,
                })
                break
            case 'events':
                setStyle({
                    height: 18900 * Scale,
                    backgroundPositionX: -9249 * Scale,
                    backgroundPositionY: -12830 * Scale,
                })
                break
            case 'seasonal reports':
                setStyle({
                    height: 15600 * Scale,
                    backgroundPositionX: -8593 * Scale,
                    backgroundPositionY: -10522 * Scale,
                })
                break
            case 'ramadan':
                setStyle({
                    height: 4500 * Scale,
                    backgroundPositionX: -273 * Scale,
                    backgroundPositionY: -3436 * Scale,
                })
                break
            case 'christmas':
                setStyle({
                    height: 4600 * Scale,
                    backgroundPositionX: -1814 * Scale,
                    backgroundPositionY: -3515 * Scale,
                })
                break

            default:
                setStyle({
                    height: 9100 * Scale,
                    backgroundPositionX: -390 * Scale,
                    backgroundPositionY: -415 * Scale,
                })
                break
        }
    }, [Scale, name])

    return (
        <div className={styles.iconWrapper + ' ' + className} style={{minWidth: Size, maxWidth: Size, height: Size}}>
            <div className={styles.icon} {...props} style={style}/>
        </div>
    )
}

Icon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.oneOf([
        'horn', 'purpose', 'vision', 'mission', 'horn-mail',
        'clients', 'orders', 'rate', 'score', 'analytics',
        'branding', 'strategy', 'cream', 'black-friday', 'linkedin', 'facebook', 'whatsapp', 'instagram', 'tiktok','youtube',
        'phone', 'map', 'email', 'message', 'welcome',
        'person-1', 'person-2', 'person-3', 'person-4', 'person-5',
        'news', 'success stories', 'educational videos', 'insights', 'e-commerce dictionary', 'press', 'events', 'seasonal reports',
        'ramadan', 'christmas'
    ]),
    scale: (props, propName, componentName) => {
        if (!props.scale && !props.size) {
            return new Error(`One of props 'scale' or 'size' was not specified in '${componentName}'.`)
        }
    },
    size: (props, propName, componentName) => {
        if (!props.scale && !props.size) {
            return new Error(`One of props 'size' or 'scale' was not specified in '${componentName}'.`)
        }
    }
}

export default Icon
