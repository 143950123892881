import styles from 'styles/components/sections/Banner.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Image from 'components/Image'

const Banner = ({ content, className, VectorImage, imageSrc, imageStyle, isGreenTriangle, isBlackTriangle }) => {
    return (
        <Section className={styles.banner + ' ' + className}>
            {
                isGreenTriangle && (
                    <div className={styles.greenTriangle}></div>
                )
            }
            {
                isBlackTriangle && (
                    <div className={styles.blackTriangle}></div>
                )
            }
            <Container className={styles.container + ((isGreenTriangle || isBlackTriangle) ? (' ' + styles['container--indent']) : '')}>
                <div className={styles.content}>
                    {
                        content.title &&
                        <h3 className={styles.title}>
                            {content.title}
                        </h3>
                    }
                    {
                        content.titles &&
                        content.titles.map((title, index) => (
                            <>
                                <h3 className={styles.title}>
                                    {title}
                                </h3>
                                {
                                    index > 0 &&
                                    <br />
                                }
                            </>
                        ))
                    }
                    {
                        content.subtext && (
                            <p className={styles.subtext}>
                                {content.subtext}
                            </p>
                        )
                    }
                    {
                        content.subtexts &&
                        content.subtexts.map((subtext, index) => (
                            <p className={styles.subtext} key={index}>
                                {subtext}
                            </p>
                        ))
                    }
                    {
                        content.buttonText && (
                            <button className={styles.button}>
                                {content.buttonText}
                            </button>
                        )
                    }
                </div>
                {
                    VectorImage ? (
                        <VectorImage className={styles.image} style={imageStyle} />
                    ) : (
                        <Image className={styles.image} src={imageSrc} alt="banner" style={imageStyle} />
                    )
                }
            </Container>
        </Section>
    )
}

export default Banner
