import Header from 'components/sections/Header'
import FAQs from 'components/pages/homepage/FAQ'
import Footer from 'components/sections/Footer'
import ScrollToTop from 'components/ScrollToTop'
import ContactUs from 'components/sections/ContactUs'
import content from 'content/pages/Homepage.json'
import React from 'react';

const FAQ = () => {
    return (
        <>
            <ScrollToTop />
            <Header />
            <FAQs content={content.faq} isHomePage={false} />
            <ContactUs />
            <Footer />
        </>
    )
}

export default FAQ