import {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'

import styles from 'styles/components/pages/lab/Blogs.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Image from 'components/Image'

const Blogs = ({content, activeCategories}) => {
    const [maxShowCount, setMaxShowCount] = useState(3)
    const [activeBlogs, setActiveBlogs] = useState([])

    useEffect(() => {
        const result = []

        content.forEach((blog) => {
            if (activeCategories[blog.category]) {
                result.push(blog)
            }
        })

        setActiveBlogs(result)

        if (window.innerWidth > 1024 && result.length > 3) {
            setMaxShowCount(15)
        }
    }, [activeCategories, content])

    return (
        <Section className={styles.blogs}>
            <div className={styles.greenTriangle}></div>
            <div className={styles.blackTriangle}></div>
            <Container className={styles.container}>
                <div className={styles.logoWrapper}>
                    <Image className={styles.logo} src="/media/default.webp"/>
                </div>
                <div className={styles.list}>
                    {
                        activeBlogs.map((blog, index) => {
                            if (index > maxShowCount - 1) {
                                return null
                            }

                            return (
                                <div className={styles.item} key={index}>
                                    <NavLink className={styles.link} to={blog.link}>
                                        <div className={styles.imageWrapper}>
                                            <Image className={styles.image} src={blog.image}/>
                                            <span className={styles.category}>
                                                {blog.category}
                                            </span>
                                        </div>
                                        <h4 className={styles.title}>
                                            {blog.title}
                                        </h4>
                                        <div className={styles.content}>
                                            {blog.content}
                                        </div>
                                    </NavLink>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    maxShowCount < activeBlogs.length &&
                    <div className={styles.showMore}>
                        <button className={styles.button} onClick={() => setMaxShowCount(maxShowCount + 3)}>
                            Load More
                        </button>
                    </div>
                }
            </Container>
        </Section>
    )
}

export default Blogs
