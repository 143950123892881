import PropTypes from 'prop-types'
import {NavLink as NavigationLink} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'

const NavLink = (props) => {
    return (
        props.hashLink ?
            <HashLink to={props.to} className={props.className}>
                {props.children}
            </HashLink> :
            <NavigationLink
                to={props.to}
                className={({isActive}) => props.className + (isActive ? ` ${props.activeClassName}` : '')}>
                {props.children}
            </NavigationLink>
    )
}

NavLink.defaultProps = {
    to: '#',
    hashLink: false
}

NavLink.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    children: PropTypes.elementType.isRequired,
    hashLink: PropTypes.bool,
}

export default NavLink
