import React from 'react';
import styles from '../styles/components/Loading.module.scss'

const Touplo = () => {
  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingContent}>
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingContent}>
            <img src={"media/homepage/CnnbToUplo-loader.gif"} alt="Loading GIF" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Touplo;