import styles from 'styles/components/sections/JoinTeam.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Icon from 'components/Icon'
import React, { useState, useRef, useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import content from 'content/components/sections/JoinTeam.json'
import { ReCaptchaContext } from 'App';
import { handleSubmitCV } from 'utils/strapiReq';
import Swal from 'sweetalert2';
import style from '../../styles/components/Swal.module.scss';

const JoinTeam = ({ darkButton = false }) => {

  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { reCaptchaLoaded, generateReCaptchaToken } = useContext(ReCaptchaContext);
  const StrapiUrl = process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "http://localhost:1337";

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (selectedFile && selectedFile.size <= maxSize) {
      setFile(selectedFile);
      setFormValues((prevValues) => ({
        ...prevValues,
        files: selectedFile,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        files: '',
      }));
    } else {
      setFile(null);
      setFormValues((prevValues) => ({
        ...prevValues,
        files: null,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        files: 'File size exceeds the limit of 2MB.',
      }));
    }
  };


  const handleFileRemove = () => {
    setFile(null);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = {};

    // Validate email field
    if (!formValues.Email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.Email)) {
      errors.Email = 'Invalid email format';
    }

    // Validate mobile field
    if (!formValues.Mobile || !/^[\d+]+$/.test(formValues.Mobile)) {
      errors.Mobile = 'Invalid Mobile format';
    }

    // Validate required fields
    content.fields.forEach((field) => {
      if (field.required && !formValues[field.name]) {
        errors[field.name] = 'This field is required';
      }
    });

    if (Object.keys(errors).length === 0) {
      try {
        const token = await generateReCaptchaToken('contactcreation');
        if (token) {
          const formData = new FormData();
          formData.append('files', file); // Append the file to the formData
          const result = await handleSubmitCV(formValues, formData, StrapiUrl, token);
          setFile(null);
          Swal.fire({
            title: result.title,
            text: result.text,
            icon: result.icon,
            customClass: {
              container: style.container,
              title: style.title,
              confirmButton: style.button,
              closeButton: style.button,
              cancelButton: style.button,
              popup: style.container,
            },
          });
          setFormValues({});  
           setFile(null);
          setLoading(false);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Please try again later (;',
            icon: 'error',
            customClass: {
              container: style.container,
              title: style.title,
              confirmButton: style.button,
              popup: style.container,
            },
          });
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      } 
    } else {
      setFormErrors(errors);
      setLoading(false);
      setFile(null);
    }
  };


  return (
    <section>
      <Section className={styles.joinTeam}>
        <Container className={styles.container}>
          <div className={styles.persons}>
            {
              [1, 2, 3, 4, 5].map((item, index) => {
                return (
                  <div key={index} className={styles.person}>
                    <Icon name={`person-${item}`} smallSize={60} size={80} />
                  </div>
                )
              })
            }
            <div className={styles.person + ' ' + styles.personText}>
              {content.personText}
            </div>
          </div>
          <div className={styles.text}>
            <h1 className={styles.title}>{content.title}</h1>
            <p className={styles.description}>{content.descriptionTitle}</p>
            <p className={styles.description}>{content.description}</p>
            <div className={styles.buttonComtainer}>
              <button className={`${styles.button} ${darkButton ? styles['button--dark'] : ''}`} onClick={() => setShowForm(true)}>
                {content.button}
              </button>
              <a
                href="https://linkedin.com/company/uplo-io/jobs/"
                className={`${styles.button} ${darkButton ? styles['button--dark'] : ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {content.button2}
              </a>
            </div>

          </div>
        </Container>
      </Section>
      {showForm && (
        <Section className={`${styles.formSection}`}>
          <Container className={styles.container}>
            <h3 className={styles.title}>Let's Talk </h3>
            <form>
              {content.fields &&
                content.fields.map((field) => (
                  <div className={`${styles.field} ${styles.field__half}`} key={field.name}>
                    <label>
                      {field.label}
                      {field.required && <span className={styles.required}> *</span>}
                    </label>
                    <input
                      type={field.type}
                      placeholder={field.placeHolder}
                      name={field.name}
                      value={formValues[field.name] || ''}
                      onChange={(e) =>
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          [field.name]: e.target.value,
                        }))
                      }
                      required={field.required}
                    />
                    {formErrors[field.name] && <p className={styles.error}>{formErrors[field.name]}</p>}
                  </div>
                ))}
              <div className={styles.field}>
                <label>Upload Your CV</label>
                {file ? (
                  <div>
                    <div className={styles.cvContainer}>
                      <input className={styles.cvField} value={file.name} readOnly />
                      <div className={styles.closeButton}>
                        <AiOutlineClose className={styles.closeIcon} onClick={handleFileRemove} />
                      </div>
                    </div>
                    {formErrors.files && <p className={styles.error}>{formErrors.files}</p>}
                  </div>
                ) : (
                  <div className="file-input">
                    <button className={`${styles.cvButton} ${darkButton ? styles['cvButton--dark'] : ''}`}
                      type="button" onClick={handleButtonClick}>
                      Upload CV
                    </button>
                    <p style={{ fontSize: '12px' }}>PDF, DOCX, DOC</p>
                    {formErrors.files && <p className={styles.error}>{formErrors.files}</p>}
                    <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} style={{ display: 'none' }} ref={fileInputRef} name='files' />
                  </div>
                )}
              </div>

              <button
                className={`${styles.sendButton} ${darkButton ? styles['sendButton--dark'] : ''}`}
                onClick={handleSubmit} disabled={loading && !reCaptchaLoaded}>
                {loading ? (
                  "loading..."
                ) :
                  "Send"
                }
              </button>
            </form>
          </Container>
        </Section>
      )}
    </section>
  )
}

export default JoinTeam
