import {NavLink} from 'react-router-dom'

import styles from 'styles/components/pages/careers/FollowCard.module.scss'

import Image from 'components/Image'


const FollowCard = ({index, activeIndex, setActiveIndex, imgPath, title, description, linkTo, icon}) => {
    return (
        <div className={styles.item + (activeIndex === index ? ' ' + styles.itemActive : '')}
             onClick={() => setActiveIndex(index)}>
            <Image src={imgPath} alt={`stories-${index}`} className={styles.image}/>
            <NavLink to={linkTo} target="_blank">
                <Image src={icon} alt={'icon'} className={styles.icon}/>
            </NavLink>
            <div className={styles.content}>
                <div className={styles.desc}>
                    <h5 className={styles.title}>
                        {title}
                    </h5>
                    <p className={styles.description}>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FollowCard
