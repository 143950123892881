import {NavLink} from 'react-router-dom'
import {useState} from 'react'

import styles from 'styles/components/sections/PartnersClients.module.scss'
import {routes} from 'utils/routes'

import Container from 'components/sections/Container'
import Section from 'components/sections/Section'
import Image from 'components/Image'

const PartnersClients = ({content, isDark}) => {
    const [activeTab, setActiveTab] = useState(0)

    return (
        <Section className={`${styles.partnersClients} ${isDark ? '' : styles.light}`}>
            {
                isDark && (
                    <div className={styles.blackTriangle}></div>
                )
            }

            <Container className={styles.container}>
                <div className={`${styles.controls} ${isDark ? '' : styles.light}`}>
                    {
                        content.controls.titles.map((title, index) => (
                            <h4 className={styles.controlsTitle + ' ' + (activeTab === index ? styles.controlsTitleActive : '')}
                                onClick={() => setActiveTab(index)} key={index}>
                                {title}
                            </h4>
                        ))
                    }
                </div>
                <div className={styles.tabs}>
                    {
                        content.tabs.map((tab, index) => (
                            <div className={styles.tab + ' ' + (activeTab === index ? styles.tabActive : '')} key={index}>
                                <div className={`${styles.content} ${isDark ? '' : styles.light}`}>
                                    <h5 className={styles.title}>
                                        {tab.title}
                                    </h5>
                                    <p className={styles.subtitle}>
                                        {tab.subtitle}
                                    </p>
                                    {tab.link && (
                                        <div className={styles.linkWrapper}>
                                            <NavLink className={styles.link} to={routes[tab.route] ?? routes.root}>
                                                {tab.link}
                                            </NavLink>
                                        </div>
                                    )}
                                </div>
                                <div className={`${styles.images} ${isDark ? '' : styles.light}`}>
                                    {
                                        tab.images.map((image, index) => (
                                            <Image src={`/media/logos/${tab.route}/${image}-${isDark ? 'white' : 'black'}.webp`}
                                                   alt={image} className={styles.image} key={index}/>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
        </Section>
    )
}

export default PartnersClients
