export const scrollAnimation = (fromRef, toRef, setAnimPercent) => {
    const getPos = (element) => element?.getBoundingClientRect()
    const topPos = (element) => getPos(element)?.top

    const onScroll = () => {
        const screenHeight = window.innerHeight

        const fromTop = topPos(fromRef.current)
        const toTop = topPos(toRef.current)

        const userLowerThanFromMiddle = fromTop <= screenHeight / 2
        const userUpperThanToMiddle = toTop >= screenHeight / 2

        if (!userLowerThanFromMiddle) {
            setAnimPercent(100)
        } else if (userLowerThanFromMiddle && userUpperThanToMiddle) {
            // Play animation when user is between from and to
            const percent = (toTop - screenHeight / 2) / (toTop - fromTop) * 100
            setAnimPercent(Math.round(percent * 100) / 100)
        } else if (!userUpperThanToMiddle) {
            setAnimPercent(0)
        }
    }

    const onResize = () => {
        window.removeEventListener('scroll', onScroll)

        if (window.innerWidth < 1024) {
            setAnimPercent(0)
        } else {
            onScroll()
            window.addEventListener('scroll', onScroll)
        }
    }
    onResize()
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
}
