import React, {useState} from 'react'
import styles from 'styles/components/pages/careers/FollowUs.module.scss'
import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import FollowCard from './FollowCard'

const FollowUs = ({content}) => {
    const [activeIndex, setActiveIndex] = useState(0)

    return (
        <Section className={styles.FollowUS}>
            <Container className={styles.container}>
                <div className={styles.text}>
                    <h1 className={styles.title}>{content.title}</h1>
                    <p className={styles.description}>{content.description}</p>
                </div>

                <div className={styles.items}>
                    {
                        content.items.map((item, index) => (
                            <FollowCard activeIndex={activeIndex} setActiveIndex={setActiveIndex}
                                        imgPath={item.imgPath} index={index} title={item.title} icon={item.icon}
                                        description={item.description} linkTo={item.linkTo} key={index}/>
                        ))
                    }
                </div>
            </Container>
        </Section>
    )
}

export default FollowUs
