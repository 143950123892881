export const routes = {
    root: '/',
    aboutUs: '/about-us',
    careers: '/careers',
    contactUs: '/contact-us',
    homepage: '/homepage',
    partners: '/our-market',
    solutions: '/solutions',
    lab: '/lab',
    blogFromBricksToClicks:'/blog/from-bicks-toClicks',
    blogConversationAndCommerce:'/blog/conversation-and-commerce',
    blogLorealSuccessStory:'/blog/loreal-success-story',
    blogBlackFridayRecord:'/blog/black-friday-record',
    blogFashionD2C:'/blog/fashion-d2c',
    blogOmniChannelSurvey:'/blog/omni-channel-survey',
    blogOnlineShoppingEid:'/blog/online-shopping-eid',
    blogBeautyAndPersonalCare:'/blog/beauty-and-personal-care',
    blogRefundsAndReturns:'/blog/refunds-and-returns',
    blogMenaRegionBrands:'/blog/mena-region-brands',
    BlogGenerateSales:'/blog/generate-sales',
    BlogOnlineRetailTrends:'/blog/online-retail-trends',
    MENAEcommerceReport2022:'/blog/mena-ecommerce-report-2020',
    UAEStateOfRetailReport2022:'/blog/uae-state-of-retail-report-2022',
    PowerOfEcommerceMarketplaces:'/blog/power-of-ecommerce-marketplaces',
    subscribeToNewsLetter: '/subscribe',
    MENAOnlineRetailReport2023:'/blog/mena-online-retail-report-2023',
    SupplyChainFromFactoryTillLastMile:'/blog/supply-chain-from-factory-till-last-mile',
    notFound: '/404',
    // thankYou: '/thank-you',
    faq:'/faq',
    fallback: '*'
}

export const ids = {
    strategy: '#strategy',
    implementation: '#implementation',
    optimization: '#optimization',

    fallback: '#',
}
