import styles from 'styles/components/pages/homepage/FAQ.module.scss'
import React, { useState } from 'react'
import Container from 'components/sections/Container'
import Section from 'components/sections/Section'
import Dropdown from 'components/Dropdown'
import { NavLink } from 'react-router-dom'

const FAQ = ({ content, isHomePage }) => {
    const [ShowAllFAQ, setShowAllFAQ] = useState(isHomePage ? false : true);
    const [FAQtoShow, setFAQtoShow] = useState(isHomePage ? content.list.slice(0, 5) : content.list);
    const handleShowAllFAQ = () => {
        if (!ShowAllFAQ) {
            setShowAllFAQ(true);
            setFAQtoShow(content.list);
        }
    };
    return (
        <Section className={styles.faqs}>
            <Container className={styles.container}>
                <h3 className={styles.title}>{content.title}</h3>
                <ul className={styles.list}>
                    {
                        FAQtoShow.map((faq, index) => (
                            <li key={index} className={styles.faq}>
                                <Dropdown className={styles.dropdown}
                                    halfRotation={true}
                                    isActive={faq.isActive}
                                    title={<h5 className={styles.dropdownTitle}>{faq.title}</h5>}
                                    activeClassName={styles['dropdown--active']}
                                    buttonClassName={styles.button}>
                                    <>
                                        {
                                            faq.link1 &&
                                            <p className={styles.content}>
                                                {faq.text1}
                                                <a className={styles.link} href={faq.link1}>Link</a>
                                                {faq.text2}
                                                {
                                                    faq.link2 &&
                                                    <>
                                                        <a className={styles.link} href={faq.link2}>Link</a>
                                                        {faq.text3}
                                                    </>
                                                }
                                            </p>
                                        }
                                        {
                                            faq.content &&
                                            <p className={styles.content}>{faq.content}</p>
                                        }
                                        <p className={styles.text}> {faq.text}</p>
                                        <ul>
                                            {faq.bullets &&
                                                faq.bullets.map((bullet, index) => (
                                                    <li key={index}>
                                                        {bullet}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </>
                                </Dropdown>
                            </li>
                        ))
                    }
                </ul>
                {
                    isHomePage === true && (
                        <NavLink className={styles.loadMore} to='/faq'>
                            show more FAQS
                        </NavLink>
                    )
                }

                {ShowAllFAQ === false && isHomePage === false && (

                    <button className={styles.loadMore} onClick={handleShowAllFAQ}>
                        show more FAQS
                    </button>

                )}

            </Container>
        </Section >
    )
}

export default FAQ
