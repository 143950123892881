import Header from 'components/sections/Header'
import Map from 'components/pages/contactus/Map'
import Banner from 'components/sections/Banner'
import ContactInfo from 'components/pages/contactus/ContactInfo'
import { default as ContactUsSection } from 'components/sections/ContactUs'
import Footer from 'components/sections/Footer'
import MainImage from 'assets/aboutus/SectionOne.webp'
import MapImage from 'assets/homepage/map.webp'
import content from 'content/pages/ContactUs.json'
import React from 'react';

const ContactUs = () => {


    return (
        <>
            <Header />
            <Banner isGreenTriangle
                content={content.banner}
                imageSrc={MainImage}
                imageStyle={{ width: '30%' }} />
            <Map />
            <Banner content={content.mapBanner}
                imageSrc={MapImage} />
            <ContactInfo content={content.contactInfo} />
            <ContactUsSection isDark />
            <Footer />
        </>
    )
}

export default ContactUs
