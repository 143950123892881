import { useState } from 'react';
import styles from 'styles/components/sections/StImOpt.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

const StlmOptInner = ({ content, tab, tabIndex, activeTab }) => {
    const cardsCount = content.tabs.length;
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const secondInnerSwiperParams = {
        slidesPerView: 1,
        spaceBetween: 10,
        onSwiper: (swiper) => {
            setSwiper(swiper);
            swiper.on('slideChange', () => {
                setActiveIndex(swiper.activeIndex);
            });
        },
    };

    const handleButtonNavClick = (index) => {
        if (swiper) {
            swiper.slideTo(index);
        }
    };

    return (
        <>
            <div className={`${styles.tabTitle} ${styles[`tabTitle--${content.color}`]}`}>
                {tab.title}
            </div>
            {tab.description &&
                <p className={styles.paragraph} key={tabIndex}>
                    {tab.description}
                </p>
            }
            <Swiper {...secondInnerSwiperParams} className={styles.contentContainer}>
                {tab.bullets && tab.bullets.map((bullet, bulletIndex) => (
                    <SwiperSlide key={bulletIndex}>
                        <div className={styles.bulletContent}>
                            {bullet.description &&
                                <p className={styles.paragraph} key={bulletIndex}>
                                    {bullet.description}
                                </p>
                            }
                            <ul>
                                {bullet.bullets &&
                                    bullet.bullets.map((bullet, index) => (
                                        <li className={styles.listClass} key={index}>
                                            {bullet}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {tab.bullets && tabIndex === activeTab && (
                <div className={styles.dots}>
                    {Array(tab.bullets.length).fill(null).map((_, idx) => (
                        <div
                            className={styles.dotContainer}
                            key={idx}
                        >
                            <div
                                className={`${styles.dot} ${idx === activeIndex ? styles.dotActive : ''}`}
                                onClick={() => {
                                    handleButtonNavClick(idx);
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default StlmOptInner;
