import { routes } from 'utils/routes'
import { NavLink } from 'react-router-dom'

import styles from 'styles/components/pages/partners/Lab.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import StackCards from 'components/sections/StackCards'
import Card from 'components/sections/Card'
import Image from 'components/Image'
import Icon from 'components/Icon'

const Lab = ({ content }) => {
    return (
        <Section className={styles.lab}>
            <div className={styles.blackRectangle}></div>
            <Container className={styles.container}>
                <div className={styles.cards}>
                    <StackCards xShift={-50} scaleShift={0.05} IsOurMarket={true}>
                        {
                            content.cards.map((card, index) => (
                                <Card key={index} className={styles.card}>
                                    <div className={styles.content}>
                                        <div className={styles.desc}>
                                            <h5 className={styles.title}>
                                                {card.title}
                                            </h5>
                                            <p className={styles.subtitle}>
                                                {card.subtitle}
                                            </p>
                                            <div className={styles.linkWrapper}>
                                                <NavLink className={styles.link} to={card.blogLink}>
                                                    {card.button}
                                                </NavLink>
                                                <NavLink className={styles.link} to={routes.lab}>
                                                    {card.link}
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className={styles.imageWrapper}>
                                            <Image src={card.image} alt={card.title} draggable="false"
                                                className={styles.image} />
                                            <div className={styles.circle}>
                                                <Icon name={card.icon} size={60} className={styles.circle} />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            ))
                        }
                    </StackCards>
                </div>
            </Container>
        </Section>
    )
}

export default Lab
