import {Shape} from 'three'
import {useRef, useState} from 'react'
import {useFrame} from '@react-three/fiber'
import {Html} from '@react-three/drei'

import styles from 'styles/components/pages/homepage/Mesh.module.scss'

const Mesh = ({hovered, tileColor, frontSideHtml, backSideHtml}) => {
    const extrudeSettings = {depth: 8, bevelEnabled: true, bevelSegments: 2, steps: 2, bevelSize: 1, bevelThickness: 1}

    const width = 100, height = 150, radius = width / 2
    const x = 0, y = 0, z = 0, rx = 0, ry = 0, rz = 0, sx = 1, sy = 1, sz = 1

    const shape = () => {
        const shape = new Shape()

        shape.moveTo(x - radius, y - height / 2 + radius)
        shape.lineTo(x - radius, y + height / 2 - radius)
        shape.arc(x + radius, height / 2 - radius, radius, Math.PI, 0, true)
        shape.lineTo(x + radius, y - height / 2 + radius)
        shape.arc(x - radius, y - height / 2 + radius, radius, 0, Math.PI, true)

        return shape
    }

    const meshRef = useRef()
    const [frontSideHidden, setFrontSideHidden] = useState(false)
    const [backSideHidden, setBackSideHidden] = useState(true)

    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => {
        const rotationDelta = 3 * delta
        if (hovered) {
            if (meshRef.current.rotation.y < Math.PI) {
                meshRef.current.rotation.y += rotationDelta
            }
        } else {
            if (meshRef.current.rotation.y > 0) {
                meshRef.current.rotation.y -= rotationDelta
            }
        }

        // Hide sides, depending on rotation
        if (meshRef.current.rotation.y < Math.PI / 2) {
            setFrontSideHidden(false)
            setBackSideHidden(true)
        } else {
            setFrontSideHidden(true)
            setBackSideHidden(false)
        }
    })

    return (
        <mesh ref={meshRef} receiveShadow={true}
              position={[x, y, z]}
              rotation={[rx, ry, rz]}
              scale={[sx, sy, sz]}>

            <Html pointerEvents="none" as="div"
                  className={styles.frontSide + ' ' + (frontSideHidden ? styles.hiddenSide : '') }
                  position={[0, 0, 9]}
                  scale={[10, 10, 10]}
                  transform
            >
                {frontSideHtml}
            </Html>

            <Html pointerEvents="none" as="div"
                  className={styles.backSide + ' ' + (backSideHidden ? styles.hiddenSide : '')}
                  position={[0, 0, 0]}
                  scale={[10, 10, 10]}
                  transform
            >
                {backSideHtml}
            </Html>

            <extrudeGeometry args={[shape(), extrudeSettings]}/>
            <meshPhongMaterial color={tileColor}/>
        </mesh>
    )
}

export default Mesh