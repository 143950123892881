import styles from 'styles/components/pages/homepage/ReportPopUp.module.scss'
import style from '../../../styles/components/Swal.module.scss';
import React, { useEffect, useState, useRef } from 'react';
import Container from 'components/sections/Container'
import Image from 'components/Image'
import Swal from 'sweetalert2';
import { handleSubmitGetReport } from 'utils/strapiReq';
import content from 'content/components/sections/ReportPopUp.json';

const ReportPopUp = ({ show, onClose }) => {
    const [formValues, setFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const StrapiUrl = process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "http://localhost:1338";
    const hiddenInputRef = useRef();

    useEffect(() => {
        if (show) {
            document.addEventListener('click', handleCloseOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleCloseOutsideClick);
        };
    }, [show]);

    const handleCloseOutsideClick = (e) => {
        if (!e.target.closest(`.${styles.popupContent}`)) {
            onClose();
        }
    };

    const handleSubmitFuction = (e) => {
        e.preventDefault();
        setLoading(true);
        const errors = {};
        // Validate email field
        if (!formValues.Email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.Email)) {
            errors.Email = 'Invalid email format';
        }
        // Validate required fields
        content.fields.forEach((field) => {
            if (field.required && !formValues[field.name]) {
                errors[field.name] = 'This field is required';
            }
        });

        const requestKeyId = hiddenInputRef.current.value; // Get the hidden input's value

        if (Object.keys(errors).length === 0) {

            e.preventDefault();
            console.log(formValues);
            const data = {
                "Name": formValues.FullName,
                "Email": formValues.Email,
                "file": {
                    "set": [{ "id": requestKeyId }]
                }
            };
            setLoading(true);
            handleSubmitGetReport(data, StrapiUrl)
                .then(response => {
                    Swal.fire({
                        title: response.title,
                        text: response.text,
                        icon: response.icon,
                        html: response.html,
                        customClass: {
                            container: style.container,
                            title: style.title,
                            confirmButton: style.button,
                            closeButton: style.button,
                            cancelButton: style.button,
                            popup: style.container
                        }
                    });
                    if (response.title === "Success") {
                        const headers = {};
                        response.data?.headers.forEach((value, name) => {
                            headers[name] = value;
                        });
                       

                        if (headers['content-type'] === "application/pdf") {
                            return response.data.blob();

                        } return false;
                    }
                    return false;

                })
                .then(pdfBlob => {
                    if (pdfBlob) {
                        // Create a URL for the Blob
                        const url = URL.createObjectURL(pdfBlob);

                        // Create a link element to trigger the download
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'Uplo-MENA-Ecommerce-Report-2023.pdf'; // Set the desired file name

                        // Trigger a click event to start the download
                        a.click();

                        // Release the Blob URL after the download
                        URL.revokeObjectURL(url);
                    }
                    setLoading(false);
                    setFormValues({});

                })
                .catch(error => {
                    console.error('Error:', error);
                })
        } else {
            setFormErrors(errors);
            setLoading(false);
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div className={styles.popup}>
            <div className={styles.popupContent}>
                <Container className={styles.container}>
                    <div className={styles.bodyContainer}>
                        <h1 className={styles.popupTitle}>{content.title}</h1>
                        <form className={styles.formContent}>
                            <p className={styles.textPadding}>
                                {content.access}
                            </p>
                            {content.fields &&
                                content.fields.map((field, i) => (
                                    <div key={i} className={`${styles.field} ${styles.field__half}`}>
                                        <label className={styles.fieldLabel}>
                                            {field.label}
                                            {field.required && <span className={styles.required}> *</span>}
                                        </label>
                                        <input
                                            type={field.type}
                                            placeholder={field.placeHolder}
                                            name={field.name}
                                            value={formValues[field.name] || ''}
                                            onChange={(e) =>
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    [field.name]: e.target.value,
                                                }))
                                            }
                                            required={field.required}
                                        />
                                        {formErrors[field.name] &&
                                            <p className={styles.error}>{formErrors[field.name]}</p>
                                        }
                                    </div>
                                ))}

                            <input
                                type="hidden"
                                name="requestkey"
                                ref={hiddenInputRef}
                                value="1"
                            />
                            <button className={styles.button} onClick={handleSubmitFuction} disabled={loading}>
                                {loading ? (
                                    "loading..."
                                ) :
                                    "Get It Now"
                                }
                            </button>
                        </form>
                        <p className={styles.popupSubTitle}>{content.subTitle}</p>
                        <p className={styles.body} >{content.body}</p>
                        <ul>
                            {
                                content.bullets &&
                                content.bullets.map((text, index) => (
                                    <li className={styles.popupList} key={index}>
                                        {text}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className={styles.imageContainer}>
                        <Image className={styles.image} src={content.image} />
                    </div>
                </Container>

            </div>
        </div>

    )
}

export default ReportPopUp
