//import styles from 'styles/components/pages/careers/Careers.module.scss'
import Header from 'components/sections/Header'
import CareersHero from 'components/pages/careers/CareersHero'
import VisionList from 'components/sections/VisionList'
// import VideoPlayer from 'components/sections/VideoPlayer'
import Team from 'components/sections/Team'
import JoinTeam from 'components/sections/JoinTeam'
import FollowUs from 'components/pages/careers/FollowUs'
import Footer from 'components/sections/Footer'
import React from 'react';
import content from 'content/pages/Careers.json'

const Careers = () => {


    return (
        <>
            <Header />
            <CareersHero content={content.careersHero} />
            <VisionList isShadow content={content.visionList} />
            {/* <VideoPlayer className={styles.videoPlayer} Video={Demo} content={content.videoPlayer}/> */}
            <JoinTeam darkButton />
            <Team content={content.team} />
            <FollowUs content={content.followUs} />
            <Footer />
        </>
    )
}

export default Careers
