import {useState} from 'react'
import {Canvas} from '@react-three/fiber'

import styles from 'styles/components/pages/homepage/BrandsTile.module.scss'

import Mesh from 'components/pages/homepage/Mesh'


const BrandsTile = ({ activateHover, ...props }) => {
    const [hovered, setHovered] = useState(props.defaultHovered);
    const shouldActivateHover = activateHover === null ? false : activateHover;
  
    return (
      <Canvas
        className={styles.canvas + ' ' + props.className}
        camera={{ position: [0, 0, 150] }}
        onMouseEnter={() => {
          if (shouldActivateHover) setHovered(true);
        }}
        onMouseLeave={() => {
          if (shouldActivateHover) setHovered(false);
        }}
      >
        {/*<OrbitControls/>*/}
        <ambientLight intensity={0.2} />
        <pointLight position={[1000, 1000, 2000]} intensity={1} color={props.tileColor} />
        <spotLight position={[-1000, -1000, -2000]} intensity={0.25} />
  
        <Mesh hovered={hovered} {...props} />
      </Canvas>
    );
  };

export default BrandsTile
