import styles from 'styles/components/sections/VisionList.module.scss'

import VisionCard from 'components/sections/VisionCard'
import Icon from 'components/Icon'
import Section from 'components/sections/Section'
import Container from 'components/sections/Container'

const VisionList = ({content, isShadow}) => {
    return (
        <Section className={styles.visionList}>
            <Container className={styles.container}>
                {
                    content.visionCards.map((item, index) => (
                        <VisionCard key={index} title={item.title} text={item.text} isShadow={isShadow}
                                    Icon={<Icon className={styles.icon} name={item.icon} smallSize={100} size={200}/>}/>
                    ))
                }
            </Container>
        </Section>
    )
}

export default VisionList
