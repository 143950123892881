import styles from 'styles/components/pages/blog/BlogSide.module.scss'
import Image from 'components/Image'
import { NavLink } from 'react-router-dom'

const BlogSide = ({ content, allContent }) => {
    function formatDate(dateString) {
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: '2-digit',
            year: '2-digit'
        }).format(new Date(dateString));

        return formattedDate;
    }
    function getLatestBlogs() {
        const sortedBlogs = Object.values(allContent).sort((a, b) => new Date(b.date) - new Date(a.date));
        const latestFourBlogs = sortedBlogs.slice(0, 4);
        return latestFourBlogs;
    }
    const lastestBlogs = getLatestBlogs();
    return (
        <div className={styles.blogSide}>
            <div className={styles.blogSideWrapper}>
                <div className={styles.currentImage}>
                    {content.headerImage ? (
                        <Image src={content.headerImage} alt="Blog" />
                    ) : null}
                </div>
                <div className={content.headerImage ? styles.latest : styles.latestNoHeader}>
                    <h2 className={styles.mainTitle}>Latest posts:</h2>
                    <div className={styles.postsList}>
                        {
                            lastestBlogs &&
                            lastestBlogs.map((data) => (
                                <NavLink to={data.link} key={data.link}>
                                    <div className={styles.post} href="">
                                    {
                                        data.headerImage ? (
                                            <Image className={styles.image} alt="blog post" src={data.headerImage} />
                                        ) : data.firstImage ? (
                                            <Image className={styles.image} alt="blog post" src={data.firstImage} />
                                        ) : data.postImage ? (
                                            <Image className={styles.image} alt="blog post" src={data.postImage} />
                                        ) : null
                                    }
                                        <div className={styles.info}>
                                            <p>{formatDate(data.date)}</p>
                                            <h3>{data.title}</h3>
                                        </div>
                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogSide
