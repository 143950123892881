import { useEffect, useState, useRef } from 'react';
import styles from 'styles/components/sections/StImOpt.module.scss'
import Image from 'components/Image';
import { Swiper, SwiperSlide } from 'swiper/react';

const StImOpt = ({ content}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTile, setActiveTile] = useState('');
  const innerSwiperRef = useRef(null);

  const innerSwiperParams = {
    slidesPerView: 1,
    spaceBetween: 10,
    onSlideChange: (swiper) => {
      setActiveTab(swiper.activeIndex);
    },
  };

  const handleButtonClick = (index) => {
    setActiveTab(index);
    if (innerSwiperRef.current && innerSwiperRef.current.swiper) {
      innerSwiperRef.current.swiper.slideTo(index);
    }
  };

  useEffect(() => {
    if (innerSwiperRef.current && innerSwiperRef.current.swiper) {
      innerSwiperRef.current.swiper.slideTo(activeTab);
    } else {
      setActiveTab(0);
    }
  }, []);

  return (
    <>
      <div className={styles.tabsWrapper}>
        <div className={styles.buttons}>
          { content.withTabs && content.tabs.map((tab, index) => ( 
            <button
              key={index}
              onClick={() => handleButtonClick(index)}
              className={`${styles.button} ${styles[`button--${content.color}`]} ${
                activeTab === index ? styles[`button--${content.color}--active`] : ''
              }`}
            >
              {tab.tabname}
            </button>
          ))}
        </div>
      </div>
      <Swiper {...innerSwiperParams} ref={innerSwiperRef}>
        {content.tabs.map((tab, index) => (
          <SwiperSlide key={tab.title}>
            <div>
              <div className={`${styles.tab} ${activeTab === index ? styles['tab--active'] : ''}`}>
                <div className={styles.texting}>
                  <Image src={tab.image} alt={tab.title} className={styles.image} />
                  <div className={styles.content}>
                    {tab.tiles && activeTile !== '' ? (
                      <>
                        {content.tabs[activeTile.split('-')[0]].tiles[activeTile.split('-')[1]].title && (
                          <div className={`${styles.tabTitle} ${styles[`tabTitle--${content.color}`]}`}>
                            {content.tabs[activeTile.split('-')[0]].tiles[activeTile.split('-')[1]].title}
                          </div>
                        )}
                        {content.tabs[activeTile.split('-')[0]].tiles[activeTile.split('-')[1]].description && (
                          <p className={styles.paragraph}>
                            {content.tabs[activeTile.split('-')[0]].tiles[activeTile.split('-')[1]].description}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <div className={`${styles.tabTitle} ${styles[`tabTitle--${content.color}`]}`}>
                          {tab.title}
                        </div>
                        <div className={styles.contentContainer}>
                          <div>
                            <p className={styles.paragraph} key={index}>
                              {tab.description}
                            </p>
                            <ul>
                              {tab.bullets &&
                                tab.bullets.map((bullet, index) => (
                                  <li className={styles.listClass} key={index}>
                                    {bullet}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          {tab.description1 && (
                            <div>
                              <>
                                <p className={styles.paragraph} key={index}>
                                  {tab.description1}
                                </p>
                                <ul>
                                  {tab.bullets1 &&
                                    tab.bullets1.map((bullet, index) => (
                                      <li className={styles.listClass} key={index}>
                                        {bullet}
                                      </li>
                                    ))}
                                </ul>
                              </>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default StImOpt;
