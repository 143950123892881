import styles from 'styles/components/pages/homepage/Banner.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Image from 'components/Image'
import upLogo from 'assets/upLogo.webp'
import loLogo from 'assets/loLogo.webp'

const Banner = ({content}) => {
    return (
        <Section className={styles.banner}>
            <Container className={styles.container}>
                <div className={styles.titleContainer}>
                    <h2 className={styles.title}>{content.titleLeft}</h2>
                    <Image className={styles.upImage} src={upLogo}/>   
                </div>
                <div className={styles.videoWrapper}>
                    <video className={styles.video} autoPlay playsInline muted loop poster={content.thumbnail}>
                        <source src={content.video} type="video/mp4"/>
                    </video>
                </div>
                <div className={styles.titleContainer}>
                <Image className={styles.loImage} src={loLogo}/>   
                    <h2 className={styles.title}>{content.titleRight}</h2>
                </div>
            </Container>
        </Section>
    )
}

export default Banner
