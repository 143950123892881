import React from 'react';
import { FacebookShareButton } from 'react-share';
import { BsFacebook } from 'react-icons/bs';

const ShareFacebookButton = ({ url, hashTag }) => {
    return (
        <div>
            <FacebookShareButton
                url={url}
                beforeOnClick={() => {
                    window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${url}&hashtag=%23${hashTag}`,
                        '_blank'
                    );
                    return false;
                }}>
              
                    <BsFacebook size={30} />
              
            </FacebookShareButton>
        </div>
    );
};

export default ShareFacebookButton;
