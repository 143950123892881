import PropTypes from 'prop-types'

import styles from 'styles/components/sections/Section.module.scss'

const Section = (props) => {
    return (
        <section className={`${styles.section} ${props.className}`}>
            {props.children}
        </section>
    )
}

Section.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default Section
