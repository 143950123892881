import styles from 'styles/components/sections/ContactUs.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Icon from 'components/Icon'
import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { handleSubmitContact } from 'utils/strapiReq';
import content from 'content/components/sections/ContactUs.json'
// import useReCaptcha from 'hooks/useReCaptcha'
import { ReCaptchaContext } from 'App';
import style from '../../styles/components/Swal.module.scss';

const ContactUs = ({ isDark }) => {
    const [showForm, setShowForm] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    // const { reCaptchaLoaded, generateReCaptchaToken } = useReCaptcha()
    const { reCaptchaLoaded, generateReCaptchaToken } = useContext(ReCaptchaContext);

    const StrapiUrl = process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "http://localhost:1337";

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const errors = {};
        // Validate email field
        if (!formValues.Email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.Email)) {
            errors.Email = 'Invalid email format';
        }
        // Validate Phone field
        if (!formValues.Phone || !/^[\d+]+$/.test(formValues.Phone)) {
            errors.Phone = 'Invalid Mobile format';
        }
        // Validate required fields
        content.fields.forEach((field) => {
            if (field.required && !formValues[field.name]) {
                errors[field.name] = 'This field is required';
            }
        });
        // In case the error object is empty
        if (Object.keys(errors).length === 0) {
            generateReCaptchaToken('contactcreation')
                .then((token) => {
                    if (token) {
                        handleSubmitContact(formValues, StrapiUrl, token)
                            .then((res) => {
                                Swal.fire({
                                    title: res.title,
                                    text: res.text,
                                    icon: res.icon,
                                    customClass: {
                                        container: style.container,
                                        title: style.title,
                                        confirmButton: style.button,
                                        closeButton: style.button,
                                        cancelButton: style.button,
                                        popup: style.container,
                                    },
                                });
                                setFormValues({});
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.log(error, 'error');
                                setLoading(false);
                            });
                    } else {
                        let result = {
                            icon: 'error',
                            title: 'Error',
                            text: 'Please try again later (;',
                        };
                        Swal.fire({
                            title: result.title,
                            text: result.text,
                            icon: result.icon,
                            customClass: {
                                container: style.container,
                                title: style.title,
                                confirmButton: style.button,
                                popup: style.container,
                            },
                        });
                    }
                })
                .catch((error) => {});
        } else {
            setFormErrors(errors);
            setLoading(false);
            e.target.reset();
        }
    };


    return (
        <section>
            <Section className={styles.cta + (isDark ? ' ' + styles['cta--dark'] : '')}>
                {
                    !isDark && <div className={styles.whiteCircle}></div>
                }
                <Container className={styles.container}>
                    <div className={styles.text}>
                        <h1 className={styles.title}>{content.title}</h1>
                        <p className={styles.description}>{content.description}</p>
                        <button className={styles.button} onClick={() => setShowForm(true)}>
                            {content.button}
                        </button>
                    </div>
                    <div>
                        <Icon name='horn' smallSize={150} size={400} className={styles.image} />

                    </div>
                </Container>
            </Section>
            {showForm && (
                <Section className={`${styles.formSection}`}>
                    <Container className={styles.container}>
                        <h3 className={styles.title}>Let's Talk </h3>
                        <form onSubmit={handleSubmit}>
                            {content.fields &&
                                content.fields.map((field, i) => (
                                    <div key={i} className={`${styles.field} ${styles.field__half}`}>
                                        <label>
                                            {field.label}
                                            {field.required && <span className={styles.required}> *</span>}
                                        </label>
                                        <input
                                            type={field.type}
                                            placeholder={field.placeHolder}
                                            name={field.name}
                                            value={formValues[field.name] || ''}
                                            onChange={(e) =>
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    [field.name]: e.target.value,
                                                }))
                                            }
                                            required={field.required}
                                        />
                                        {formErrors[field.name] && (
                                            <p className={styles.error}>{formErrors[field.name]}</p>
                                        )}
                                    </div>
                                ))}
                            <div className={styles.field}>
                                <label>{content.textArea.label}</label>
                                <textarea placeholder={content.textArea.placeHolder} value={formValues[content.textArea.name] || ''} onChange={(e) =>
                                    setFormValues((prevValues) => ({
                                        ...prevValues,
                                        [content.textArea.name]: e.target.value,
                                    }))
                                } name={content.textArea.name} rows="5" resize='none' required={content.textArea.required}></textarea>
                            </div>
                            <button className={styles.button} disabled={loading || !reCaptchaLoaded}>
                                {loading ? (
                                    "loading..."
                                ) :
                                    "Send"
                                }
                            </button>
                        </form>
                    </Container>
                </Section>
            )}
        </section>
    )
}

export default ContactUs
