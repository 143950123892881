import Header from 'components/sections/Header'
import Footer from 'components/sections/Footer'
import styles from '../styles/components/notfound.module.scss';
import { NavLink } from 'react-router-dom';
import React from 'react';

const NotFound = () => {
    return (
        <>
            <Header />
            <>
                <div className={styles.container} >
                    <h1 className={styles.header}>
                        404
                    </h1>
                    <div className={styles.title}>
                        <h1>Page Not Found</h1>
                        <br />
                        <p>The page you're looking for does not seem to exist</p>
                    </div>
                    <NavLink className={styles.gohome} to='/'>
                        Go Home
                    </NavLink>
                </div>
            </>

            <Footer />
        </>
    )
}

export default NotFound
