import styles from 'styles/components/pages/aboutus/WhyWork.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import VisionList from 'components/sections/VisionList'

const WhyWork = ({ content }) => {
    return (
        <Section className={styles.whyWork}>
            <Section className={styles.whyWork}>
                <Container className={styles.container}>
                    <h1 className={styles.title}>{content.aboutUsTitle}</h1>
                    <div className={styles.whiteText}>
                        {content.aboutUsText &&
                            content.aboutUsText.map((text, index) => (
                                <p key={index}>
                                    {text}
                                    <br />
                                    <br />
                                </p>
                            ))
                        }
                    </div>

                </Container>
            </Section>
            <Container className={styles.container}>
                <h1 className={styles.title}>{content.title}</h1>
                <VisionList content={content.visionList} />
            </Container>
        </Section>
    )
}

export default WhyWork
