import Header from 'components/sections/Header'
import Banner from 'components/sections/Banner'
import WhyWork from 'components/pages/aboutus/WhyWork'
import KPI from 'components/sections/KPI'
import Team from 'components/sections/Team'
import Testimonials from 'components/pages/aboutus/Testimonials'
import JoinTeam from 'components/sections/JoinTeam'
import ContactUs from 'components/sections/ContactUs'
import Footer from 'components/sections/Footer'
import MainImage from 'assets/aboutus/SectionOne.webp'
import React from 'react';
import content from 'content/pages/AboutUs.json'

const AboutUs = () => {
    return (
        <>
            <Header/>
            <Banner isGreenTriangle isBlackTriangle
                    content={content.banner}
                    imageSrc={MainImage}
            />
            <WhyWork content={content.whyWork}/>
            <KPI isDark/>
            <Team content={content.team}/>
            {/* <VideoPlayer Video={Demo} content={content.videoPlayer}/> */}
            <Testimonials content={content.testimonials}/>
            <JoinTeam/>
            <ContactUs/>
            <Footer/>
        </>
    )
}

export default AboutUs
