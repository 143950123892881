import React from 'react';
import {AiFillTwitterCircle} from 'react-icons/ai'

const TwitterShareButton = ({ url, imageUrl, tweetText, via, title , hashTag}) => {
  const encodedUrl = url ? encodeURIComponent(url) : '';
  const encodedTitle = title ? encodeURIComponent(title) : '';
  const encodedImageUrl = imageUrl ? encodeURIComponent(imageUrl) : '';
  const encodedTweetText = tweetText ? (encodeURIComponent(tweetText)) : '';
  const hashtags = hashTag ? (encodeURIComponent(hashTag)) : '';

  const twitterUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&title=${encodedTitle}&via=${via}&hashtags=${hashtags}&media=${encodedImageUrl}&text=${encodedTweetText}`;

  const shareOnTwitter = () => {
    window.open(twitterUrl, '_blank');
  }

  return (
    <AiFillTwitterCircle size={36} onClick={shareOnTwitter}/>
  );
}

export default TwitterShareButton;