import { routes } from 'utils/routes'

import AboutUs from 'pages/AboutUs'
import Careers from 'pages/Careers'
// import ComingSoon from 'pages/ComingSoon' // TODO comment this page when production build released
import ContactUs from 'pages/ContactUs'
import Homepage from 'pages/Homepage'
import NotFound from 'pages/NotFound'
import Partners from 'pages/Partners'
import Solutions from 'pages/Solutions'
import Lab from 'pages/Lab'
import Blog from 'pages/Blog'
import FAQ from 'pages/FAQ'
import Subscribe from 'pages/Subscribe'
// import ThankYou from 'pages/ThankYou'

export const getPagesRoutes = () => {
    // return [
    //     { // TODO comment this route when production build released
    //         name: 'ComingSoon',
    //         path: routes.fallback,
    //         PageComponent: ComingSoon,
    //     },
    // ]

    return [
        // Root page
        {
            name: 'Root',
            path: routes.root,
            PageComponent: Homepage,
        },
        {
            name: 'Blog',
            path: routes.blogConversationAndCommerce,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogFromBricksToClicks,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogLorealSuccessStory,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogBlackFridayRecord,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogFashionD2C,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogOmniChannelSurvey,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogOnlineShoppingEid,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogBeautyAndPersonalCare,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogRefundsAndReturns,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.blogMenaRegionBrands,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.BlogGenerateSales,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.MENAEcommerceReport2022,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.UAEStateOfRetailReport2022,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.ecom,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.PowerOfEcommerceMarketplaces,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.BlogOnlineRetailTrends,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.MENAOnlineRetailReport2023,
            PageComponent: Blog,
        },
        {
            name: 'Blog',
            path: routes.SupplyChainFromFactoryTillLastMile,
            PageComponent: Blog,
        },
        {
            name: 'AboutUs',
            path: routes.aboutUs,
            PageComponent: AboutUs,
        },
        {
            name: 'Careers',
            path: routes.careers,
            PageComponent: Careers,
        },
        {
            name: 'ContactUs',
            path: routes.contactUs,
            PageComponent: ContactUs,
        },
        {
            name: 'Homepage',
            path: routes.homepage,
            PageComponent: Homepage,
        },
        {
            name: 'Partners',
            path: routes.partners,
            PageComponent: Partners,
        },
        {
            name: 'Solutions',
            path: routes.solutions,
            PageComponent: Solutions,
        },
        {
            name: 'Lab',
            path: routes.lab,
            PageComponent: Lab,
        },
        {
            name: 'Faq',
            path: routes.faq,
            PageComponent: FAQ,
        },
        {
            name: 'NotFound',
            path: routes.notFound,
            PageComponent: NotFound,
        },
        {
            name: 'Fallback',
            path: routes.fallback,
            PageComponent: NotFound,
        },
        {
            name: 'Subscribe',
            path: routes.subscribeToNewsLetter,
            PageComponent: Subscribe,
        },
        // {
        //     name: 'ThankYou',
        //     path: routes.thankYou,
        //     PageComponent: ThankYou,
        // },
    ]
}
