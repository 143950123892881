import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/sections/KPI.module.scss';

import Section from 'components/sections/Section';
import Container from 'components/sections/Container';
import Icon from 'components/Icon';

import defaultContent from 'content/components/sections/KPI.json';

const KPI = ({ className, isDark, content }) => {
  const kpiContent = (content && content.content)|| defaultContent;

  return (
    <Section className={styles.kpi + ' ' + className + (isDark ? ' ' + styles.kpiDark : '')}>
      <Container className={styles.container}>
        <h3 className={styles.header}>{kpiContent.title}</h3>
        <div className={styles.items}>
          {kpiContent.KPI.map((item, index) => (
            <div className={styles.item} key={index}>
              <Icon name={item.icon} size={100} className={styles.image} />
              <h4 className={styles.title}>{item.title}</h4>
              <p className={styles.description}>{item.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

KPI.defaultProps = {
  isDark: false,
};

KPI.propTypes = {
  className: PropTypes.string,
  isDark: PropTypes.bool,
  content: PropTypes.any,
};

export default KPI;