const LiveChat = () => {
    (function () {
        var APP_UUID = '690b5e2e-3864-4359-9d7d-125297f6a3c4';
        var BASE_COLOR = '#000000';
        var CONTAINER_ELEMENT = 'iframe';

        var process = void 0;
        var _typeofThat="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(o){return typeof o}:function(o){return o&&"function"==typeof Symbol&&o.constructor===Symbol&&o!==Symbol.prototype?"symbol":typeof o};

        // eslint-disable-next-line no-unused-expressions,no-mixed-operators,no-sequences
        function insertLivechatJS(){var e=document.createElement("script");e.type="text/javascript",e.async="true",e.src=(process&&process.env&&process.env.DOMAIN||"https://livechat.connexease.com")+"/l/embed-js/livechat.js";var t=document.createElement("link");t.rel="stylesheet",t.href=(process&&process.env&&process.env.DOMAIN||"https://livechat.connexease.com")+"/l/embed-css/livechat_embed.css";var n=document.getElementsByTagName("script")[0];document.head.appendChild(t),n.parentNode.insertBefore(e,n)}window.attachEvent?window.attachEvent("onload",insertLivechatJS):window.addEventListener("load",insertLivechatJS,!1),window.initLivechat=function(){"object"===_typeofThat(window.LiveChat)&&window.LiveChat.boot({uuid:APP_UUID,baseColor:BASE_COLOR,containerElement:CONTAINER_ELEMENT})};
    })();
}

export default LiveChat




// TODO: Implement env in staging server and apply below commented Code
// /* eslint-disable no-use-before-define */
// const LiveChat = () => {
//     (function () {
       
//         // eslint-disable-next-line no-mixed-operators
//         var APP_UUID  = (process&&process.env&&process.env.REACT_CHAT_API_KEY||"690b5e2e-3864-4359-9d7d-125297f6a3c4")

//         var BASE_COLOR = '#000000';
//         var CONTAINER_ELEMENT = 'iframe';

//         var process = void 0;
//         var _typeofThat="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(o){return typeof o}:function(o){return o&&"function"==typeof Symbol&&o.constructor===Symbol&&o!==Symbol.prototype?"symbol":typeof o};

//         // eslint-disable-next-line no-unused-expressions,no-mixed-operators,no-sequences
//         function insertLivechatJS(){var e=document.createElement("script");e.type="text/javascript",e.async="true",e.src=(process&&process.env&&process.env.DOMAIN||"https://livechat.connexease.com")+"/l/embed-js/livechat.js";var t=document.createElement("link");t.rel="stylesheet",t.href=(process&&process.env&&process.env.DOMAIN||"https://livechat.connexease.com")+"/l/embed-css/livechat_embed.css";var n=document.getElementsByTagName("script")[0];document.head.appendChild(t),n.parentNode.insertBefore(e,n)}window.attachEvent?window.attachEvent("onload",insertLivechatJS):window.addEventListener("load",insertLivechatJS,!1),window.initLivechat=function(){"object"===_typeofThat(window.LiveChat)&&window.LiveChat.boot({uuid:APP_UUID,baseColor:BASE_COLOR,containerElement:CONTAINER_ELEMENT})};
//     })();
// }

// export default LiveChat
