import Header from 'components/sections/Header'
import Banner from 'components/pages/partners/Banner'
import KPI from 'components/pages/partners/KPI'
import Brands from 'components/sections/Brands'
// import Stories from 'components/sections/Stories'
import Lab from 'components/pages/partners/Lab'
import ContactUs from 'components/sections/ContactUs'
import Footer from 'components/sections/Footer'
import FAQ from 'components/pages/homepage/FAQ'
import content from 'content/pages/Partners.json'
import React from 'react';

const Partners = () => {
    return (
        <>
            <Header />
            <Banner content={content.banner} />
            <KPI content={content.kpis} />
            <Brands activateHover={true} content={content.brands} />
            {/* <Stories content={content.stories}/> */}
            <Lab content={content.lab} />
            <ContactUs />
            <FAQ content={content.faq} isHomePage={true} />
            <Footer />
        </>
    )
}

export default Partners
