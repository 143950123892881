import styles from 'styles/components/pages/careers/CareersHero.module.scss'

import Container from 'components/sections/Container'
import Section from 'components/sections/Section'

import {ReactComponent as BackImage} from 'assets/homepage/UO.svg'

const CareersHero = ({content}) => {
    return (
        <Section className={styles.careersHero}>
            <div className={styles.backImages}>
                <BackImage className={styles.backImage}/>
                <BackImage className={styles.backImage}/>
                <BackImage className={styles.backImage}/>
                <BackImage className={styles.backImage}/>
            </div>
            <Container className={styles.container}>
                <div className={styles.text}>
                    {
                        content.titleRows.map((row, index) => (
                            <h1 className={styles.title} key={index}>{row}</h1>
                        ))
                    }
                </div>
            </Container>
            <div className={styles.greenTriangle}></div>
            <div className={styles.blackTriangle}></div>
        </Section>
    )
}

export default CareersHero
