import PropTypes from 'prop-types'

import styles from 'styles/components/sections/Container.module.scss'

const Container = (props) => {
    return (
        <div className={`${styles.container} ${props.className}`}>
            {props.children}
        </div>
    )
}

Container.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
}

export default Container
