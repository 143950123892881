import { routes } from 'utils/routes'
import Swal from 'sweetalert2';
import { NavLink as NavigationLink } from 'react-router-dom'
import { handleSubmit } from 'utils/strapiReq';

import style from '../../styles/components/Swal.module.scss';

import styles from 'styles/components/sections/Footer.module.scss'
import React, { useState } from 'react';

import Container from 'components/sections/Container'
import NavLink from 'components/NavLink'
import Image from 'components/Image'
import Icon from 'components/Icon'

import Logo from 'assets/logo-white.webp'

import content from 'content/components/sections/Footer.json'

const Footer = () => {
    const links = [
        routes.aboutUs,
        routes.solutions,
        routes.lab,
        routes.partners,
        routes.careers,
        routes.contactUs
    ]
    const [loading, setLoading] = useState(false);

    const StrapiUrl = process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "http://localhost:1337";

    function handleNewsletterSubmit(e) {
        e.preventDefault();
        setLoading(true);
        handleSubmit(e, StrapiUrl)
            .then((res) => {
                Swal.fire({
                    title: res.title,
                    text: res.text,
                    icon: res.icon,
                    customClass: {
                        container: style.container,
                        title: style.title,
                        confirmButton: style.button,
                        closeButton: style.button,
                        cancelButton: style.button,
                        popup: style.container
                    }
                });
                e.target.email.value = '';
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setLoading(false);
            });    
    }
    const handleEmail = () => {
        window.location.href = `mailto:Hi@uplo.io`;
    };

    return (
        <footer className={styles.footer}>
            <div className={styles.strip} />

            <Container className={styles.container}>
                <div className={styles.navigation}>
                    <NavigationLink to={routes.root}>
                        <Image src={Logo} alt="logo" className={styles.logo} />
                    </NavigationLink>
                    <ul className={styles.navigationLinks}>
                        {
                            links.map((link, index) => (
                                <li className={styles.linksItem} key={index}>
                                    <NavLink
                                        to={link}
                                        className={index === links.length - 1 ? styles.contactUsLink : styles.link}
                                        activeClassName={styles.linkActive}
                                    >
                                        {content.links[index]}
                                    </NavLink>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className={styles.border}></div>
                <div className={styles.content}>
                    <div className={styles.contact}>
                        <div className={styles.contactUs}>
                            <div className={styles.title}>
                                <NavLink to={routes.contactUs} className={styles.title}>
                                    {content.contactUs.title}
                                </NavLink>
                            </div>
                            <button className={styles.button} onClick={handleEmail}>
                                {content.contactUs.button}
                            </button>
                        </div>
                        <div className={styles.socialIcons}>
                            <NavigationLink to="https://www.linkedin.com/company/cnnb-solutions/" className={styles.socialLink} target="_blank">
                                <Icon size={30} name="linkedin" className={styles.socialIcon} />
                            </NavigationLink>
                            <NavigationLink to="https://www.instagram.com/uplo.io/" className={styles.socialLink} target="_blank">
                                <Icon size={30} name="instagram" className={styles.socialIcon} />
                            </NavigationLink>
                            <NavigationLink to="https://www.tiktok.com/@uplo.io" className={styles.socialLink} target="_blank">
                                <Icon size={30} name="tiktok" className={styles.socialIcon} />
                            </NavigationLink>
                            <NavigationLink to="https://www.youtube.com/channel/UC4-bUJiU4Em6K1NodnE94CQ" className={styles.socialLink} target="_blank">
                                <Icon size={30} name="youtube" className={styles.socialIcon} />
                            </NavigationLink>
                        </div>

                    </div>
                    <div className={styles.subscribe}>
                        <div className={styles.subscribeTo}>
                            <div className={styles.title}>
                                {content.subscribeTo.title}
                            </div>


                            <form className={styles.form} onSubmit={handleNewsletterSubmit}>

                                <input type="text" id="email" className={styles.input}
                                    placeholder="e-mail & subscription consent" />
                                <button type="submit" className={styles.submit} disabled={loading}>
                                    
                                    {loading ? (
                                            "loading..."    
                                    ):
                                    (content.subscribeTo.submit)
                                    }
                                </button>

                            </form>
                        </div>

                        <div className={styles.info}>
                            <Icon name="email" size={50} className={styles.icon} />
                            <h1 className={styles.emailTitle}>{content.email.title}</h1>
                            <a className={styles.emailLink} href={`mailto:${content.email.link}`}>{content.email.link}</a>
                        </div>

                    </div>
                </div>
            </Container>

            <div className={styles.bottom}>
                {content.bottom.replace('{year}', new Date().getFullYear())}
            </div>
        </footer>
    )
}

export default Footer
