import { useEffect, useState, useRef } from 'react';
import styles from 'styles/components/sections/StImOpt.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import StlmOptInner from './StlmOptInner';
import Image from 'components/Image';

const StImOpt = ({ content }) => {
  const [activeTab, setActiveTab] = useState(0);
  const innerSwiperRef = useRef(null);
  const innerSwiperParams = {
    slidesPerView: 1,
    spaceBetween: 10,
    onSlideChange: (swiper) => {
      setActiveTab(swiper.activeIndex);
    },
  };

  const handleButtonClick = (index) => {
    setActiveTab(index);
    if (innerSwiperRef.current && innerSwiperRef.current.swiper) {
      innerSwiperRef.current.swiper.slideTo(index);
    }
  };

  useEffect(() => {
    if (innerSwiperRef.current && innerSwiperRef.current.swiper) {
      innerSwiperRef.current.swiper.slideTo(activeTab);
    } else {
      setActiveTab(0);
    }
  }, []);

  return (
    <>
      <div className={styles.tabsWrapper}>
        <div className={styles.buttons}>
          {content.tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleButtonClick(index)}
              className={`${styles.button} ${styles[`button--${content.color}`]} ${activeTab === index ? styles[`button--${content.color}--active`] : ''
                }`}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>
      <Swiper {...innerSwiperParams} ref={innerSwiperRef}>
        {content.tabs.map((tab, tabIndex) => (
          <SwiperSlide key={tabIndex}>
            <div>
              <div className={`${styles.tab} ${activeTab === tabIndex ? styles['tab--active'] : ''}`}>
                <div className={styles.texting}>
                  <Image src={tab.image} alt={tab.title} className={styles.image} />
                  <div className={styles.content}>
                    <StlmOptInner tab={tab} content={content} tabIndex={tabIndex} activeTab={activeTab} />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default StImOpt;
