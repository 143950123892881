import {useState} from 'react'

import styles from 'styles/components/pages/partners/Banner.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Image from 'components/Image'

const Banner = ({content}) => {
    const [activeIndex, setActiveIndex] = useState(2)

    return (
        <Section className={styles.banner}>
            <Container className={styles.container}>
                <div className={styles.content}>
                    {
                        content.content.map((image, index) => (
                            <div key={index} className={styles.contentItem + activeIndex === index
                            }>
                                {
                                    image.src &&
                                <Image src={image.src} alt={image.alt} className={styles.contentImage}/>
                                }
                                <h3 className={styles.title}>{image.title}</h3>
                                <p className={styles.text}>{image.text}</p>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.images}>
                    {
                        content.images.map((image, index) => (
                            <div key={index} className={styles.imageWrapper + (activeIndex === index ? ' ' + styles['imageWrapper--active'] : '')}
                                    onClick={() => setActiveIndex(index)}>
                                <Image src={image.src} alt={image.alt} className={styles.image}/>
                            </div>
                        ))
                    }
                </div>
            </Container>
        </Section>
    )
}

export default Banner
