import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getPagesRoutes } from 'utils/pagesRoutes';
import ScrollToTop from 'components/ScrollToTop';
import ReactGA from 'react-ga4';

const Router = () => {
  useEffect(() => {
    ReactGA.initialize('G-P18VS7XKZL');
    ReactGA.send("pageview")  
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {getPagesRoutes().map((page) => (
          <Route
            key={page.name}
            path={page.path}
            element={<page.PageComponent />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
