import PropTypes from 'prop-types'

import styles from 'styles/components/sections/Card.module.scss'

import { ReactComponent as UO } from 'assets/homepage/UO.svg'

const Card = ({ index: cardIndex, cardsCount, activateSlide, children, className }) => {
    return (
        <div className={styles.card + ' ' + className}>
            <UO className={styles.UO} />

            {children}

            <div className={styles.dots}>
                {
                    Array(cardsCount).fill().map((value, index) =>
                        <div className={styles.dotContainer} onClick={() => {
                            if (index !== cardIndex) {
                                activateSlide(index)
                            }
                        }} >

                            <button key={index}
                                className={styles.dot + ' ' + (index === cardIndex ? styles.dotActive : '')} />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

Card.propTypes = {
    index: PropTypes.number,
    cardsCount: PropTypes.number,
    activateSlide: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
}

export default Card