import {useRef} from 'react'
import {NavLink} from 'react-router-dom'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation} from 'swiper'
import 'swiper/css'

import styles from 'styles/components/sections/Team.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Image from 'components/Image'

import {ReactComponent as BackImage} from 'assets/homepage/UO.svg'
import CircleImage from 'assets/homepage/UO-circle.webp'
import LinkedinIcon from 'assets/icons/linkedin.svg'
import {ReactComponent as Arrow} from 'assets/menu/arrow.svg'

const Team = ({content}) => {
    const sliderRef = useRef(null)

    return (
        <Section className={styles.team}>
            <Container className={styles.container}>
                <BackImage className={styles.backImage}/>
                <div className={styles.head}>
                    <h1 className={styles.title}>{content.title}</h1>
                    <p className={styles.subtitle}>{content.subtitle}</p>
                    <Image src={CircleImage} alt="UO" className={styles.circleImage}/>
                </div>
            </Container>

            <Swiper
                ref={sliderRef}
                className={styles.swiper}
                modules={[Navigation]}
                centeredSlides={true}
                initialSlide={0}
                slidesPerView={4}
                gap={30}
                breakpoints={{
                    300: {
                        slidesPerView: 1.3,
                        spaceBetween: 10
                    },
                    500: {
                        slidesPerView: 2
                    },
                    1000: {
                        slidesPerView: 4,
                        // spaceBetween:10,
                    },
                }}>

                {
                    content.slides.map((slide, index) => (
                        <SwiperSlide style={{margin: 0}} key={index} className={styles.gap}>
                            {({isActive}) => (
                                <div className={` ${styles.card} ${isActive ? styles['card--active'] : ''}`}>
                                    <Image src={slide.image} className={styles.image}/>
                                    <div className={styles.content}>
                                        <h2 className={styles.name}>
                                            {slide.name}
                                        </h2>
                                        <p className={styles.position}>
                                            {slide.position}
                                        </p>
                                        {
                                            index !== 0 && (
                                                <div className={styles.arrowWrap + ' ' + styles.arrowLeft}
                                                     onClick={() => sliderRef.current.swiper.slidePrev()}>
                                                    <Arrow alt="arrow" className={styles.arrow}/>
                                                </div>
                                            )
                                        }
                                        {
                                            index !== content.slides.length - 1 && (
                                                <div className={styles.arrowWrap + ' ' + styles.arrowRight}
                                                     onClick={() => sliderRef.current.swiper.slideNext()}>
                                                    <Arrow alt="arrow" className={styles.arrow}/>
                                                </div>
                                            )
                                        }
                                    </div>

                                    {
                                        slide.shortDescriptionLines &&
                                        <div className={styles.description}>
                                            {
                                                slide.shortDescriptionLines.map((line, index) => (
                                                    <p className={styles.descriptionLine} key={index}>
                                                        {line}
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    }
                                    <NavLink className={styles.linkedin} to={slide.linkedin} target="_blank">
                                        <Image className={styles.icon} alt="linkedin" src={LinkedinIcon} />
                                    </NavLink>
                                </div>
                            )}
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <div className={styles.greenBack}></div>
        </Section>
    )
}

export default Team
