import PropTypes from 'prop-types'

import styles from 'styles/components/pages/homepage/Ribbon.module.scss'

// import Section from 'components/sections/Section'
import Image from 'components/Image'

const Ribbon = ({ content, isDarkMode }) => {
    return (
        <div>
            <div className={styles.ribbon}>
                {
                    ['primary', 'secondary'].map((className, index) => (
                        <div key={index} className={`${styles[className]} ${isDarkMode ? styles.dark : ''}`}
                            style={{ minWidth: content.images.length * 100 * 2 }}>
                            {
                                content.images.map((image, index) => (
                                    <Image key={index} src={`/media/logos/clients/${image}-${isDarkMode ? 'black' : 'white'}.webp`}
                                        alt={image} className={styles.image} />
                                ))
                            }
                        </div>
                    ))
                }
            </div>
            <div className={styles.secondRibbon}>
                {
                    ['secondRibbonPrimary', 'secondRibbonSecondary'].map((className, index) => (
                        <div key={index} className={`${styles[className]} ${isDarkMode ? styles.dark : ''}`}
                            style={{ minWidth: content.partnersImages.length * 100 * 2 }}>
                            {
                                content.partnersImages.map((image, index) => (
                                    <Image key={index} src={`/media/logos/partners/${image}-${isDarkMode ? 'black' : 'white'}.webp`}
                                        alt={image} className={styles.image} />
                                ))
                            }
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

Ribbon.defaultProps = {
    darkMode: false
}

Ribbon.propTypes = {
    content: PropTypes.object.isRequired,
    darkMode: PropTypes.bool
}

export default Ribbon
