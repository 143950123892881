import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useLocation } from 'react-router-dom'
import { ids, routes } from 'utils/routes'
import { scrollAnimation } from 'utils/scrollAnimation'
import BookMeeting from 'components/sections/BookMeeting'

import styles from 'styles/components/pages/homepage/Service.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import StImOptHome from 'components/sections/StImOptHome'
import NavLink from 'components/NavLink'
import { Swiper, SwiperSlide } from 'swiper/react';

const Service = ({ content }) => {
  const sliderRef = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [activeTab, setActiveTab] = useState(0)

  const outerSwiperRef = useRef(null)
  const innerSwiperRef = useRef(null)

  const outerSwiperParams = {
    slidesPerView: 1,
    spaceBetween: 10,

    navigation: true,
    onSlideChange: (swiper) => {
      setActiveSlide(swiper.activeIndex)
      setActiveTab(swiper.activeIndex)
      if (outerSwiperRef.current && outerSwiperRef.current.swiper) {
        outerSwiperRef.current.swiper.slideTo(swiper.activeIndex)
      }
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(swiper.activeIndex)
      }
    }
  }

  const goToSlide = (index) => {
    setActiveSlide(index)
    setActiveTab(index)
    if (outerSwiperRef.current && outerSwiperRef.current.swiper) {
      outerSwiperRef.current.swiper.slideTo(index)
    }
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index)
    }
  }

  const sliderSettings = {
    className: styles.asideControls,
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    ref: sliderRef
  }

  const [windowWidth, setWindowWidth] = useState()
  const [buttons, setButtons] = useState([])

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth)
    }
    onResize()
    window.addEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    setButtons([
      {
        name: 'strategize',
        color: 'secondary',
        initialStyles: {
          // transformX: windowWidth < 1200 ? 173 : 195,
          // transformY: windowWidth < 1200 ? -831 : -464,
          // fontSize: 64,
        }
      }, {
        name: 'implement',
        color: 'accent',
        initialStyles: {
          // transformX: windowWidth < 1200 ? 558 : 580,
          // transformY: windowWidth < 1200 ? -929 : -557,
          // fontSize: 64,
        }
      }, {
        name: 'optimize',
        color: 'primary',
        initialStyles: {
          // transformX: windowWidth < 1200 ? 257 : 280,
          // transformY: windowWidth < 1200 ? -941 : -563,
          // fontSize: 64,
        }
      }
    ])
  }, [windowWidth])

  const [animPercent, setAnimPercent] = useState(100) // 0 - 100
  const calcStyles = (initialTransform) => {
    const { transformX, transformY, fontSize } = initialTransform

    return {
      transform: `translateX(${transformX * animPercent / 100}px) translateY(${transformY * animPercent / 100}px)`,
      fontSize: `${fontSize + (24 - fontSize) * (1 - animPercent / 100)}px`,
    }
  }
  const titleRef = useRef(null)
  const contentRef = useRef(null)

  useLayoutEffect(() => {
    scrollAnimation(titleRef, contentRef, setAnimPercent)
  }, [])

  return (
    <Section className={styles.services}>
      <div className={styles.titleContainerWrapper}>
        <Container className={styles.titleContainer}>
          <h3 className={styles.title} ref={titleRef}>
            We <span className={`${styles.outline} ${styles['outline--secondary']}`}>strategize</span>, <span className={`${styles.outline} ${styles['outline--accent']}`}>implement</span> and <span className={`${styles.outline} ${styles['outline--primary']}`}>optimize</span> with you.
          </h3>
        </Container>
      </div>
      <div className={styles.content} ref={contentRef}>
        <Container className={styles.contentContainer}>
          <aside className={styles.aside}>
            <div className={styles.asideDesktopControls}>
              <Slider {...sliderSettings}>
                {buttons.map((button, index) => (
                  <div
                    className={`${styles.slide} ${activeSlide === index ? 'slick-center' : ''}`}
                    key={index}
                  >
                    <button
                      style={calcStyles(button.initialStyles)}
                      id={button.id}
                      className={`${styles.outline} ${styles["outline--" + button.color]} ${styles.asideButton} ${activeSlide === index ? styles.active : styles.inactive}`}
                      onClick={() => goToSlide(index)}
                    >
                      {button.name}
                    </button>
                  </div>
                ))}
              </Slider>
            </div>
            <div className={styles.asideMobileControls}>
              {buttons.map((button, index) => (
                <button
                  style={calcStyles(button.initialStyles)}
                  id={button.id}
                  key={index}
                  className={`${styles.outline} ${styles['outline--' + button.color]} ${styles.asideButton} ${activeSlide === index ? styles.active : styles.inactive}`}
                  onClick={() => goToSlide(index)}
                >
                  {button.name}
                </button>
              ))}
            </div>
          </aside>

          <div className={styles.sliderWrapper}>

            <Swiper {...outerSwiperParams} ref={outerSwiperRef}>
              {content.slider.slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <h3 className={styles.swiperTitle} id={index}>
                    {slide.title}
                  </h3>
                  <StImOptHome
                    content={slide}
                    color={buttons.color}
                    id={index}
                    innerSwiperRef={innerSwiperRef}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={styles.linkWrapper}>
              {content.slider.link && (
                <NavLink
                  hashLink
                  to={routes.solutions + [ids.strategy, ids.implementation, ids.optimization][activeSlide]}
                  className={styles.linkMargin}
                >
                  {content.slider.link}
                </NavLink>
              )}
              <BookMeeting content={content.slider} />
            </div>

          </div>
        </Container>
      </div>
    </Section>
  )
}

export default Service
