import PropTypes from 'prop-types'

const Image = ({src, alt, className, ...props}) => {
    const handleImgLoadingError = (e) => {
        console.warn(`Image with name "${src}" does not exist`)
        e.target.src = '/media/default.webp'
    }

    return (
        <img src={src} alt={alt} className={className}
             onError={(e) => handleImgLoadingError(e)}
             {...props}/>
    )
}

Image.defaultProps = {
    alt: 'default placeholder image',
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
}

export default Image
