import { ids, routes } from 'utils/routes'
import { useLocation, NavLink as NavigationLink } from 'react-router-dom'

import styles from 'styles/components/sections/Header.module.scss'

import Container from 'components/sections/Container'
import Dropdown from 'components/Dropdown'
import NavLink from 'components/NavLink'
import Image from 'components/Image'
import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
import Touplo from 'components/touplo'

import { ReactComponent as Close } from 'assets/menu/close.svg'
import Logo from 'assets/logo.webp'
import LogoWhite from 'assets/logo-white.webp'
import { ReactComponent as Search } from 'assets/menu/search.svg'

import content from 'content/components/sections/Header.json'

const Header = ({ bgBlack, iconColor, mobileIconColor, logoColor}) => {
    
    const queryParameters = new URLSearchParams(window.location.search);
    const isfromCnnb = (queryParameters.get("redirection") && queryParameters.get("redirection") === 'true') ? true : false;
    let [isActiveMenu, setIsActiveMenu] = useState(false);
    let [IsLoading, setIsLoading] = useState(true);
    const curLocation = useLocation();
    const timetoRemove = (isfromCnnb) ? 5000 : 2500;
    const closeMenu = (pathname) => {
        if (pathname === curLocation.pathname) {
            setIsActiveMenu(false)
        }
    }
    useEffect(() => {
      
            const loadingTimeout = setTimeout(() => {
                setIsLoading(false);
            }, timetoRemove);

            setIsLoading(true);

            return () => {
                clearTimeout(loadingTimeout);
            };
      


    }, [timetoRemove,isfromCnnb]);

    if (IsLoading) {

        if(isfromCnnb){
          return   <Touplo /> 
        }else{
            return <Loading />;
        }
       
    }
    const submit = (e) => {
        e.preventDefault()

        // // TODO do smth with search
        // const search = e.target.search.value
    }

    const links = [
        routes.homepage,
        routes.aboutUs,
        {
            pathname: routes.solutions,
            ids: [
                ids.strategy,
                ids.implementation,
                ids.optimization,
            ]
        },
        {
            pathname: routes.lab,
            ids: [
            ]
        },
        routes.partners,
        routes.careers,
        routes.contactUs,
    ]
    const navigationLinks = content.navigationLinks.map((link, index) => {
        if (link.subLinks) {
            return (
                <li key={index} className={styles.linksItem}>
                    <Dropdown key={index} title={
                        <NavLink to={links[index].pathname}
                            className={styles.link}
                            activeClassName={styles.linkActive}>
                            {link.text}
                        </NavLink>
                    }>
                        <ul className={styles.linksMenu}>
                            {link.subLinks.map((subLink, subIndex) => (
                                <li key={subIndex} className={styles.linksMenuItem} onClick={() => closeMenu(links[index].pathname)}>
                                    <NavLink hashLink to={links[index].pathname + (links[index].ids[subIndex] ?? ids.fallback)}
                                        className={styles.link}>
                                        {subLink}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </Dropdown>
                </li>
            )
        } else {
            return (
                <li key={index} className={styles.linksItem} onClick={() => closeMenu(links[index])}>
                    <NavLink to={links[index]}
                        className={styles.link}
                        activeClassName={styles.linkActive}>
                        {link}
                    </NavLink>
                </li>
            )
        }
    })

    return (
        <header className={`${styles.header} ${bgBlack ? styles['header--black'] : ''}`}>
            <Container className={styles.container}>
                <div className={styles.logo}>
                    <NavigationLink to={routes.root}>
                        <Image className={styles.logoImage} src={logoColor === 'white' ? LogoWhite : Logo} alt="UPLO" />
                    </NavigationLink>
                </div>
                <div className={styles.menu}>
                    <button className={styles.burger} onClick={() => setIsActiveMenu(true)}>
                        <svg className={(iconColor === 'white' ? styles.iconWhite : styles.iconBlack) + ' ' + (mobileIconColor === 'black' ? styles.mobileIconBlack : '')} width="45" height="35"
                            viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H50V5.625H0V0ZM0 19.6875H50V25.3125H0V19.6875ZM0 39.375H50V45H0V39.375Z" />
                        </svg>
                    </button>
                    <div className={
                        isActiveMenu ?
                            (styles.navigation + ' ' + styles.navigation_active) :
                            styles.navigation
                    }>
                        <div className={styles.navigationContent}>
                            <button className={styles.close} onClick={() => setIsActiveMenu(false)}>
                                <Close alt="Close button" className={styles.closeImage} />
                            </button>
                            <ul className={styles.navigationLinks}>
                                {navigationLinks}
                            </ul>
                            <form className={styles.searchForm} onSubmit={submit}>
                                <input type="text" id="search" placeholder={content.search.placeholder}
                                    className={styles.searchInput} />
                                <button type="submit" className={styles.search}>
                                    <Search alt="Search button" className={styles.searchImage} />
                                </button>
                            </form>
                        </div>
                        <div className={styles.strip}></div>
                    </div>
                </div>
            </Container>
        </header>
    )
}

export default Header
