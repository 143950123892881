import styles from 'styles/components/sections/Brands.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import BrandsTile from 'components/pages/homepage/BrandsTile'
import Image from 'components/Image'

import { ReactComponent as Shapes } from 'assets/homepage/shapes.svg'

const Brands = ({ activateHover, ...content }) => {
    return (
        <Section className={styles.brands}>
            <Container className={styles.container}>
                {
                    content.title && (
                        <h4 className={styles.title}>
                            {content.title}
                        </h4>
                    )
                }
                <div className={`${styles.tiles} ${content.shapes ? '' : styles['tiles--no-shapes']}`}>
                    {content.content.tiles.map((tile, index) => (
                        <BrandsTile
                            key={index}
                            activateHover={activateHover}
                            defaultHovered={tile.defaultHovered}
                            className={`${styles.tile} ${styles[`tile--${tile.tileBg}`]}`}
                            tileColor={+tile.tileColor}
                            frontSideHtml={
                                <>
                                    {tile.frontSideHtml.text && 
                                   <div  className={styles.backSide}> <p className={styles.frontSideText}>{tile.frontSideHtml.text}</p></div>
                                    }
                                    {tile.frontSideHtml.image && (
                                        <Image src={tile.frontSideHtml.image} className={styles.frontSideImage} />
                                    )}
                                </>
                            }
                            backSideHtml={(
                                <div className={styles.backSide}>
                                    {tile.backSideHtml.image && (
                                        <Image src={tile.backSideHtml.image} className={styles.backSideImage} />
                                    )}
                                    {tile.backSideHtml.authorLines.map((line, index) => (
                                        <p className={styles.author} key={index}>{line}</p>
                                    ))}
                                    <span className={styles.quote}>“</span>
                                    <p className={styles.subtext}>
                                        {tile.backSideHtml.subtext}
                                    </p>
                                </div>
                            )}
                        />
                    ))}
                </div>
                {
                    content.shapes && (
                        <div className={styles.shapesWrapper}>
                            <Shapes className={styles.shapes} />
                        </div>
                    )
                }
            </Container>
        </Section>
    )
}

export default Brands
