import { useState } from 'react'
import Swal from 'sweetalert2';
import { handleSubmit } from 'utils/strapiReq';
import styles from 'styles/components/pages/homepage/Email.module.scss'
import style from'../../../styles/components/Swal.module.scss';

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Icon from 'components/Icon'

const Email = ({ content }) => {
    const [loading, setLoading] = useState(false);

    const StrapiUrl = process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "http://localhost:1337";
    
    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        handleSubmit(e, StrapiUrl)
            .then((res) => {
                Swal.fire({
                    title: res.title,
                    text: res.text,
                    icon: res.icon,
                    customClass: {
                        container: style.container,
                        title: style.title,
                        confirmButton: style.button,
                        closeButton: style.button,
                        cancelButton: style.button,
                        popup: style.container
                    }
                });
                e.target.email.value = '';
                setLoading(false);
            })
            .catch((error) => {
                console.log(error, "error");
                setLoading(false);
            });
    }
      

    return (
        <Section className={styles.email}>
            <div className={styles.greenTriangle}></div>
            <div className={styles.emailWrapper}>
                <Container className={styles.container}>
                    <Icon name={content.icon} size={320} className={styles.newsletter} />
                    <div className={styles.content}>
                        <h3 className={styles.title}>{content.title}</h3>
                        <form className={styles.form} onSubmit={submit}>
                            <label className={styles.label} htmlFor="email">
                                {content.label}
                            </label>
    
                            <div className={styles.actions}>
                                <input type="text" id="email" className={styles.input}
                                    placeholder={content.actions.placeholder} />

                                <button type="submit" className={styles.submit} disabled={loading}>
                                 {loading ? (
                                            "loading..."    
                                    ):
                                    ( content.actions.submit)
                                    }
                                   
                                </button>


                            </div>

                        </form>
                    </div>
                </Container>
            </div>
        </Section>
    )
}

export default Email
