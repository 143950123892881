import { useLayoutEffect, useRef, useState } from 'react'

import styles from 'styles/components/pages/lab/Categories.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Icon from 'components/Icon'
import { FaCheck } from 'react-icons/fa';

import { scrollAnimation } from 'utils/scrollAnimation'

const Categories = ({ content, activeCategories, toggleCategory, getCountActiveCategories }) => {
    const [animPercent, setAnimPercent] = useState(100) // 0 - 100

    const calcTransform = (initialTransform) => {
        const { titleX, titleY, titleRotate, imageX, imageY, imageRotate } = initialTransform
        const titleTransform = `translateX(${titleX * animPercent / 100}px) translateY(${titleY * animPercent / 100}px) rotate(${titleRotate * animPercent / 100}deg)`
        const imageTransform = `translateX(${imageX * animPercent / 100}px) translateY(${imageY * animPercent / 100}px) rotate(${imageRotate * animPercent / 100}deg)`

        return { titleTransform, imageTransform }
    }

    const headlineRef = useRef(null)
    const listRef = useRef(null)

    useLayoutEffect(() => {
        scrollAnimation(headlineRef, listRef, setAnimPercent)
    }, [])

    const handleCategoryClick = (category) => {
            toggleCategory(category.title)
    }

    return (
        <Section className={styles.categories}>
            <Container className={styles.container}>
                <h1 className={styles.headline} ref={headlineRef}>
                    {content.headline}
                </h1>
                <h2 className={styles.subtitle}>
                    {content.subtitle}
                </h2>
                <div className={styles.list} ref={listRef}>
                    {
                        content.list.map((category, index) => (
                            <div className={styles.item + (activeCategories[category.title] ? '' : ' ' + styles['item--disabled'])}
                                key={index} onClick={() => handleCategoryClick(category)}>
                                <div className={styles.title + ' ' + styles[`title--bg-${category.titleBg}`]}
                                    style={{
                                        transform: calcTransform(category.initialTransform).titleTransform,
                                    }}>
                                    {category.title}
                                </div>
                                <div className={styles.imageWrapper + ' ' + styles[`imageWrapper--bg-${category.imageBg}`]}
                                    style={{
                                        transform: calcTransform(category.initialTransform).imageTransform,
                                    }}>
                                    <div className={styles.imageInnerWrapper}>
                                        <Icon smallSize={30} size={50} name={category.title.toLowerCase()}
                                            className={styles.image + ' ' + (category?.imageInvert ? styles['image--inverted'] : '')}
                                        />
                                        {activeCategories[category.title] && (
                                            <div className={styles.checkmarkContainer}>
                                                <FaCheck size={30} className={styles.checkmark} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <h4 className={styles.subtitle + (getCountActiveCategories() !== 0 ? (' ' + styles['subtitle--hidden']) : '')}>
                    {content.filterMessage}
                </h4>
            </Container>
        </Section>
    )
}

export default Categories
