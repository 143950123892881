import React, {useState} from 'react'
import PropTypes from 'prop-types'

import styles from 'styles/components/Dropdown.module.scss'

import {ReactComponent as Arrow} from 'assets/menu/arrow.svg'

const Dropdown = (props) => {
    let [isActive, setIsActive] = useState(props.isActive)

    return (
        <div className={props.className + (isActive ? ' ' + (styles.active + ' ' + props.activeClassName) : '') + (props.halfRotation ? ' ' + styles.dropdownHalfRotation : '')}>
            <div className={styles.title + ' ' + props.titleClassName} onClick={() => setIsActive(!isActive)}>
                {props.title}
                <button className={styles.button + ' ' + props.buttonClassName}>
                    <Arrow alt="Dropdown arrow" className={styles.buttonArrow}/>
                </button>
            </div>
            <div className={styles.children}>
                {props.children}
            </div>
        </div>
    )
}

Dropdown.defaultProps = {
    halfRotation: false,
    isActive: false
}

Dropdown.propTypes = {
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    halfRotation: PropTypes.bool,
    title: PropTypes.element,
    children: PropTypes.element.isRequired,
    isActive: PropTypes.bool
}

export default Dropdown