import styles from 'styles/components/pages/contactus/ContactInfo.module.scss'

import Section from 'components/sections/Section'
import Container from 'components/sections/Container'
import Icon from 'components/Icon'

const ContactInfo = ({content}) => {
    return (
        <Section className={styles.contactInfo}>
            <Container className={styles.container}>
                <div className={styles.info}>
                    <Icon name="phone" size={50} className={styles.icon}/>
                    <h1 className={styles.title}>{content.phone.title}</h1>
                    <a className={styles.link} href={content.phone.whatsapp} target="_blank" rel="noopener noreferrer">{content.phone.link}</a>
                </div>
                <div className={styles.border}/>
                <div className={styles.info}>
                    <Icon name="map" size={50} className={styles.icon}/>
                    <h1 className={styles.title}>{content.map.title}</h1>
                    <p className={styles.description}>
                        <strong>{content.map.description.strong}</strong>
                        <span> </span>
                        {content.map.description.text}
                    </p>
                </div>
                <div className={styles.border}/>
                <div className={styles.info}>
                    <Icon name="email" size={50} className={styles.icon}/>
                    <h1 className={styles.title}>{content.email.title}</h1>
                    <a className={styles.link} href={`mailto:${content.email.link}`}>{content.email.link}</a>
                </div>
            </Container>
        </Section>
    )
}

export default ContactInfo
